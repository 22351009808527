import React, { useState, useEffect, Fragment } from 'react';
import {Helmet} from "react-helmet";
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "./Base64"
import GetNews from "./GetNews"
import GetNovinki from "./GetNovinki"
import Tags from "./Tags"
import PopularDeps from "./PopularDeps"
import Partners from "./Partners"
import Loader from "./Loader"
import YaOtziv from "./YaOtziv"
import MainAdSlider from "./BANNER/MainAdSlider_v01"

import {
  Link
} from "react-router-dom";

var bases = [];

export default function MainPage(){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const meta = useSelector(state => state.meta);
const count = useSelector(state => state.count);
const rdx_mnulist = useSelector(state => state.mnulist);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [mnulist,setMnulist] = useState({mnu:[]});
const [result,setResult] = useState('');
const [query,setQuery] = useState('');

//console.log('MAIN COUNT:',count);

useEffect(() => { 
	 
	if(count<9){
	    for (var i = 1; i < setup.bases; i++){ GetMenu(i, setup.navid[i].id); }
	} else {setMnulist({mnu:rdx_mnulist});}

}, [] );

useEffect(() => {
  	if(count>9){
		for (var i = 1; i < setup.bases; i++){ bases[i] = setup.navid[i].id; }		
		setAllIs({loading:false, error:false});
	}
}, [count]);

useEffect(() => {
  if(query !== '' && query.length > 2) {GetSearch(query)
  } else {
    setResult('');
  }
}, [query]);

if(AllIs.error){
        return <p>MainPageJS: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return <Loader/> } 

var title = meta.ttl;
document.title = title;

const CatMenu = (props) => (
	<ul>
  <li className="header">{setup.navid[props.base].t}</li>
	  {
	    props.list.mnu[props.base].map((item, id) => <li key={id}><Link to={'/'+props.bases[props.base]+'/'+item.id} title={item.ttl}>{item.ttl}</Link></li>)
	  }   
	</ul>
)

return (
  <Fragment>
    <Helmet>
    <meta charSet="utf-8" />
    <title>{ title }</title>
      <meta name="description" content={meta.dsc}/>
      <meta name="keywords" content={meta.kwrd}/>
      <link rel="canonical" href={setup._domen}/>
    </Helmet>
    <div className="pages">
      <Tags/>
      <MainAdSlider/>
      <PopularDeps/>

     

      <input placeholder='Быстрый поиск /начните вводить — Датчики' type="text" className="filter" onChange={handleSearch} autoCorrect="off" autoComplete="off"/>
        <div className="container">
            <div className="menu">
              <CatMenu list={mnulist} base="1" bases={bases}/>
            </div>
            <div className="menu">
              <CatMenu list={mnulist} base="2" bases={bases}/>
            </div>
            <div className="menu">
              <CatMenu list={mnulist} base="3" bases={bases}/>
            </div>
            <div style={{'paddingLeft':'20px'}}>
            {query !== ''? <ShowSearchResult result={result}/> : null}
            <Partners mode="block"/>
              <GetNews mode="topics"/>
              <GetNovinki mode="topics"/>
            </div>              
        </div>
        <YaOtziv/>
</div>
</Fragment>
);

function handleSearch(event){

  let mnu = rdx_mnulist.slice();
  //var t = new (Date()).getTime();
  var searchQuery = event.target.value.toLowerCase();
  var FilteredMenuList0 = mnu[1].filter((el) => {
    var searchValue = el.ttl.toLowerCase();
    return searchValue.indexOf(searchQuery) !== -1;});

  var FilteredMenuList1 = mnu[2].filter((el) => {
    var searchValue = el.ttl.toLowerCase();
    return searchValue.indexOf(searchQuery) !== -1;});

  var FilteredMenuList2 = mnu[3].filter((el) => {
    var searchValue = el.ttl.toLowerCase();
    return searchValue.indexOf(searchQuery) !== -1;});

    let  m  = mnulist.mnu.slice();
    m[1] = FilteredMenuList0;
    m[2] = FilteredMenuList1;
    m[3] = FilteredMenuList2;
    setMnulist({mnu:m});
    setQuery(event.target.value);
};

function ShowSearchResult(props){
const result = props.result;
return(
    <ul>
      {
      query.length > 2? <p>запрос: {query}</p> : null
      }
      { result.itm? !result.itm.id? 
        result.itm.map((i, key) => 
        <li key={key}>
          <Link to={i.url}>
            <span>{i.pfx+' '+i.ttl}</span>
          </Link>
        </li>
        ) : 
        <li>
          <Link to={result.itm.url}>
            <span>{result.itm.pfx+' '+result.itm.ttl}</span>
          </Link>
        </li>
        : null
      }
    </ul>
    )
}


function GetMenu(idx, cid){
	//console.log("LANG:",setup.lang);
  const form = new FormData()
  form.append('BaseNumber', idx);
  form.append('lang', setup.lang);
  form.append('CurID', cid);

  const MyInit = {method: 'POST', body: form};

  fetch(setup.domen + setup.folder + 'site-get-menu.php', MyInit)
    .then(response => {
      if (response.ok) {
      	//console.log('TS:', response.text());
        return response.json();
      } else {
        throw new Error(response.text() + 'Something went wrong ...');
      }
    })
    .then(data => {
      //this.mnuUpID = data.upid;
      //let mnulist =  data.qwn > 0 ? data.mnulist : [{ttl:'тут нчиего нет', vmode:'', depth:'1', pbl:'y', id:'hz', chq:0}];

      if(data.qwn > 0 ) {
        for (var i = 0; i < data.mnulist.length; i++) {
          data.mnulist[i].ttl = Base64.decode(data.mnulist[i].ttl);
        }
      }

      let mlist =  data.qwn > 0 ? data.mnulist : [];
      dispatch({type:'SET_MENU_LIST', payload:{idx:idx, mnulist:mlist}});
      let m = mnulist.mnu;
      m[idx] = mlist;
      setMnulist({mnu:m});
      //MenuIs[idx] = {loading:false};
      //setMenuIs(MenuIs);
      dispatch({type:'SET_COUNT', payload:idx});
    })
    .catch(error => {
      //MenuIs[idx] = {loading:false};
      //setMenuIs(MenuIs);
      setAllIs({loading:true, error:error});
    }
    );
}


function GetSearch(filtr){
  const form = new FormData()
        form.append('lang', setup.lang);
        form.append('filtr', filtr);
      
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'site-search.php', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    console.log('filt:', query, '-', data.q);
   if(query === data.q) { setResult(data); }
      //setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}


}