import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import Loader from './Loader';
import Base64 from "./Base64"

import { Link } from "react-router-dom";

import "./css/partners.css"


//import * as $ from 'jquery';
import LightBox from './js/lb'
import './css/lightBox.css'
//import './css/jquery.lightbox-0.5.css'

export default function Partners(props){
    const setup = useSelector(state => state.setup);
    const [content, setContent] = useState({loading:true, error:null, data:null});

useEffect(() => {
        //window.scroll(0, 0);
        loadContent(); 
        LightBox('a.lbpic');
}, [] );

useEffect(() => {
    LightBox('a.lbpic');
}, [content] );

const main = useMemo(() => <Render mode={props.mode}/>, [content.data]);


if(content.error){ return <p>MAIN APP: {content.error.message}</p> } 
if(content.loading){ return <Loader/> } 

const itm = content.data;
var t= itm.t;
var p = itm.p;

var ttl = p+' '+t;

let mDsc = Base64.decode(itm.mdsc);
var metDsc = mDsc !=='nodata'? mDsc : ttl;

let kwrds = Base64.decode(itm.kwrd);
var keywords = kwrds !=='nodata'? kwrds : 'партнеры, сертификаты, кип, оборудование';


//$(() => { $('a.lbpic').lightBox(); })

return( main )

function Render(props){
    return( props.mode === 'page'? RenderPage() : RenderBlock() )
}

function RenderPage(){
return(
<Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ttl}</title>
            <meta name="description" content={metDsc}/>
            <meta name="keywords" content={keywords}/>
            <link rel="canonical" href={setup._domen+'/partners'}/>
        </Helmet>        
            <div className="pages pt50">
                <h1 className="itmttl">{t}</h1>
                <ul className="partners">
                {
                    itm.itm.map((i, key) => 
                    <li key={key}>
                        <h3>{i.ttl}</h3>
                        <p><ShowContent content={i.txt}/></p>
                        <ShowImages content={i} mode="_sm"/>
                    </li>
                    )
                }
                </ul>
            </div>
            <div id="lbcont"/>
</Fragment>
    )
}

function RenderBlock(){
    const sitm = itm.itm.slice(0,2);
    return(
            <Fragment>
                    <h2 className="red">{t}</h2>
                    <ul className="infoblock pimgs">
                    {
                        sitm.map((i, key) => 
                        <li key={key}>
                            <ShowImages content={i} mode="_sm"/>
                        </li>
                        )
                    }
                    </ul>
                    <Link to="/partners"><p className="tar">далее..</p></Link>                    
            </Fragment>
    )
}
    
    function Text(props){
    let txt = Base64.decode(props.t);
    return( txt !=='nodata'? txt : null )    
    }

    function ShowContent(props){
        let dsc_txt = Base64.decode(props.content);
        if (dsc_txt ==='nodata') {return null}
        return( 
                dsc_txt !== '.'? <Fragment>
                <div className="dprtdsc" dangerouslySetInnerHTML={{ __html: sanitize(dsc_txt)}}/>
                </Fragment> : null
              ) 
        
        function sanitize(html) {
          var doc = document.createElement('div');
          doc.innerHTML = html;
          return doc.innerHTML;
        }
}

    function ShowImages(props){
        const dmn =setup._domen+setup.imgdomen+'/';
        const imgs = props.content.imgs.img;
        const imgttl = decodeURI(props.content.ttl).replace(/ /g, "_");
            return ( imgs.length? 
                        imgs.map((i, key) => (
                          <a key={key} href={dmn+imgttl+'-'+i.src} className="lbpic">
                              <img src={dmn+imgttl+'-'+i.src.slice(0, i.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={imgttl} title={imgttl}/>
                          </a>)) 
                        :
                          <a href={dmn+imgttl+'-'+imgs.src} className="lbpic">
                              <img src={dmn+imgttl+'-'+imgs.src.slice(0, imgs.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={imgttl} title={imgttl}/>
                          </a>
                    )

    }

    function loadContent(){
        const form = new FormData()
              form.append('lang', setup.lang);

        const MyInit = { 
                    method: 'POST',
                    body: form
                    };
              //fetch(setup.domen+setup.folder+'site-get-itm.php', MyInit)
              fetch(setup.domen+'api/partners', MyInit)
              .then(response => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    throw new Error(response.text()+'Something went wrong ...');
                  }
              })
      
              .then(data => {   
                   setContent({loading:false, error:null, data:data});
                })
              .catch(error => {
                   setContent({loading:true, error:error, data:null});
               });
      }
}
