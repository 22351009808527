import React from 'react';
import { useWindowScroll } from 'react-use';

export default function ScrollUp() {
const { x, y } = useWindowScroll(); 

return(
y > 300 ? <div onClick={() => window.scroll(0, 0)}><img className="top" src="/svg/2top.svg" height="45" width="40" alt="Наверх" title="Наверх" /></div>
        : null
)
}