import React, { useMemo, Fragment, useRef, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import {useWindowScroll} from 'react-use';

import Base64 from "../Base64"
import GetMenuMobile from "../Mobile/GetMenuMobile"
import GetStepNavigation from "../StepNavigation_MOB"
import AddComment from "../AddComment"
import GetPriceCALL from "../GetPriceCALL"
import GetPrice from "../GetPrice"
import ShowComments from "../Comments/ShowComments"
import ShowMinPrc from "./ShowMinPrice"

//import * as $ from 'jquery';
import LightBox from '../js/lb'
//import '../css/jquery.lightbox-0.5.css'
import '../css/lightBox.css'
import '../css/content.css'
import './showItem.css'

export default function Mobile(props){
const setup = useSelector(state => state.setup);
const brands_by_ttl = useSelector(state => state.brands.ttl);

const {x, y} = useWindowScroll();

const ShowPictures = useMemo(() => <ShowImages content={props.data} mode='_m'/>, [props.data]);
const ShowDSC = useMemo(() => <ShowContent content={props.data.dsc_txt}/>, [props.data.dsc_txt]);
const MainRender = useMemo(() => <Main/>, [props.data.dsc_txt]);

useEffect(() => {
  LightBox('a.lbpic');
}, [props.data.dsc_txt] );

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const price = useRef(null)
const executeScroll = () => scrollToRef(price)




const itm = props.data;
var t= Base64.decode(itm.t);
var p = Base64.decode(itm.pfx);

var ttl = itm.man+' '+t+' — '+p;
var mttl = ttl + ' — купить в Дельта Инжиниринг. г. Челябинск.';

let mDsc = Base64.decode(itm.meta_dsc);
var metDsc = mDsc !=='nodata'? mDsc : itm.man+' '+Base64.decode(itm.t)+' — '+Base64.decode(itm.sm_dsc);

let genKwrds = `${itm.man}, ${t}, ${p}`;
let kwrds = Base64.decode(itm.kwrd);
var keywords = kwrds ==='nodata'? genKwrds : `${genKwrds}, ${kwrds}`;
document.title = mttl;

return(
 <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{mttl}</title>
            <meta name="description" content={metDsc}/>
            <meta name="keywords" content={keywords}/>
            <link rel="canonical" href={setup._domen+itm.url}/>
        </Helmet>
  { MainRender }
</Fragment>
)

function Main(){
return(
<div className="item pages">
  <GetMenuMobile man={itm.man}/>
 <h1 className="itmttl"><span className="f10">{p}</span><br/>{itm.man} {t}</h1>
        <GetStepNavigation/>
        <table><tbody>
          <tr>
              <td className="content">
              <AddComment/>
                <div className="gallery">
                  {ShowPictures}
                </div>
                <br/>
                {itm.zak !== "n"? 
                                brands_by_ttl[itm.man].prc ==='y'?
                          <ShowMinPrc mode='showprc' prc="0"/>
                          :
                          <ShowMinPrc mode='call'/>
                          :
                          <ShowMinPrc mode='empty'/>
                }


                {
                  itm.zak !== "n"? <div onClick={executeScroll} className="btn qst w">ЦЕНЫ</div> : null
                }
              {ShowDSC}
              <div ref={price}>
              {
                         itm.zak !== "n"? 
                                            brands_by_ttl[itm.man].prc ==='y'?<GetPrice 
                                                                                  id={itm.id} 
                                                                                  ttl={{p:Base64.decode(itm.pfx), t:Base64.decode(itm.t), v:itm.man}} 
                                                                                  vmode={itm.vmode} 
                                                                                  vendor={brands_by_ttl[itm.man].id} 
                                                                                  modif={itm.modif} 
                                                                                  prc={itm.prc} 
                                                                                  valuta={itm.log}
                                                                              /> 
                                                                              : 
                                                                              <GetPriceCALL
                                                                                  id={itm.id} 
                                                                                  ttl={{p:Base64.decode(itm.pfx), t:Base64.decode(itm.t), v:itm.man}} 
                                                                                  vmode={itm.vmode} 
                                                                                  vendor={brands_by_ttl[itm.man].id} 
                                                                                  modif={itm.modif} 
                                                                                  prc={itm.prc} 
                                                                                  valuta={itm.log}
                                                                              />
                            :null
                        }
              </div>
              <h3>ВОПРОСЫ, ОТЗЫВЫ И КОММЕНТАРИИ</h3>
              <ShowComments/>
              </td>
          </tr>
        </tbody></table>
        { 
        y > 300? <div onClick={() => window.scroll(0, 0)}><img className="top" src="/svg/2top.svg" height="45" width="40" alt="top"/></div> :null
      }
</div>
)
}

function ShowImages(props){
  const dmn='/';
  const imgs = props.content.imgs;
  const imges = Base64.decode(props.content.imges);
  const imgttl = props.content.ttlpfx+'-';

  if(imgs === '' ){ 
   const ext = imges.slice(-4); 
   const impath = imges.slice(0, -4);
   return <a href={imges} className="lbpic" title={ttl}><img src={impath+props.mode+ext} alt={ttl} title={ttl}/></a>
 } else {
    return ( imgs.img[0] ? 
                imgs.img.map((i, key) => (i.p === 'y' ? 
                  <a key={key} href={dmn+imgttl+i.src} className="lbpic" title={ttl}>
                      <img src={dmn+imgttl+i.src.slice(0, i.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={ttl} title={ttl}/>
                  </a>: null)) 
                :
                  <a href={dmn+imgttl+imgs.img.src} className="lbpic" title={ttl}>
                      <img src={dmn+imgttl+imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={ttl} title={ttl}/>
                  </a>
            )

        }
}

function ShowContent(props){
const dsc_txt = props.content;
//$(() => { $('a.lbpic').lightBox(); })
return( 
        <div id="content" dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(dsc_txt))}}/>
      ) 

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}
}

}; 



