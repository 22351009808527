import React, {Fragment} from 'react';
import { useSelector } from 'react-redux'
//import Base64 from "./Base64"
import useMedia from "../js/useMedia"

import GetDPRT from "./GetDPRT2022"
import GetDprtMobile from "./GetDprtMobile"
import GetStepNavigation from "../StepNavigation"
import NotFound from "../NotFound"

import {
  useParams,
} from "react-router-dom";

import "../css/dprts.css"

export default function ShowDprt(props){

const bases = useSelector(state => state.navbases.nums);

  let { base } = useParams();
  //let { path, url } = useRouteMatch();

//let small = useMedia("(max-width:420px)");
let large = useMedia("(min-width:650px)");

if(!bases[base]){return <NotFound/>}

return(
<Fragment>
    {
      large ? 
      <div className="pages">
          <GetStepNavigation/>
          <GetDPRT/>
        </div>
      :
      <div className="item pages">
        <GetDprtMobile/>
      </div>
    }
      <hr />
      {
        //console.log('SHOW DPRT RENDER')
      }      
  </Fragment>
  );

} 
