import React, { useState, useEffect, Fragment } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import Loader from "../Loader"
import Base64 from "../Base64"
import GetBrands from "./GetBrands"
import NotFound from "../NotFound"
import { Link, useParams } from "react-router-dom";

export default function Show_BRAND(){

const setup = useSelector(state => state.setup);
const brands = useSelector(state => state.brands.ids);

const [content, setContent] = useState({loading:true, error:null, data:null});
const [notFound, setNotFound] = useState(false);


let { id } = useParams();

useEffect(() => {
    window.scroll(0, 0);
    LoadBrandSort(id, 3); 
}, [id] );



if(notFound){return <NotFound/>}
if(content.error){
        return <p>ShowBRAND: {content.error.message}</p>
    } 

  if(content.loading){ return <Loader/> } 

const d = content.data.base;
const brand = content.data.brand;

//console.log('DDD:', d[0].a);

const ShowTree = (props) =>{
const a = props.a;
//console.log('AAAA:', a);
return( 
    <ul>
      {
       a.qwn > 1 ? 
       a.itm.map((i, key) => 
          <li key={key}>
          <Link title={i.pfx+' '+i.ttl} to={i.href}>{i.pfx+' '}<span>{i.ttl}</span></Link>
              {
                i.ext === 'y'? <ShowTree a={i.a}/> : null
              }
          </li>)
       :
        <li><Link title={a.itm.pfx+' '+a.itm.ttl} to={a.itm.href}>{a.itm.pfx+' '+a.itm.ttl}</Link></li>
      }
    </ul>
  )
}
var ttl = Base64.decode(brand.pfx)+' '+brands[id].ttl;
var mttl = ttl + ' - Челябинск';
var metDsc = Base64.decode(brand.metad);
var keywords = Base64.decode(brand.metak);

return(
 <Fragment>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{ mttl }</title>
        <meta name="description" content={metDsc}/>
        <meta name="keywords" content={keywords}/>
        <link rel="canonical" href={setup._domen+'/brand/'+id}/>
    </Helmet>
<div className="pages eqSort"> 
<h1 className="itmttl">{ttl}. Челябинск.</h1>
<br/>
    <ul className="brand">
      <li>
                {
                  brand.logo.qwn > 0 ? <Fragment>
                                        <img src={'/svg/brands/'+brand.logo.doc.src} width="70%" alt={mttl}/>
                                        <br/>
                                      </Fragment> : null 
                }
              <ShowContent content={brand.data}/>
              {
              d.length > 0? 
                d.map((i, key) => 
                <Fragment key={key}>
                  <h2 key={key}>{i.pfx+' '+i.ttl}</h2>
                    <ShowTree a={i.a}/>
                </Fragment>)
                :
                <Fragment>
                  <h2>{d.pfx+' '+d.ttl}</h2> 
                    <ShowTree a={d.a}/>
                </Fragment>
              }
      </li>
      <li>
            <GetBrands notitle={false}/>
      </li>      
    </ul>
</div>
</Fragment>
)


function ShowContent(props){
let dsc_txt = Base64.decode(props.content);
if (dsc_txt ==='nodata') {return null}
return( 
        dsc_txt !== '.'? <Fragment>
        <br/>
        <div className="dprtdsc" dangerouslySetInnerHTML={{ __html: sanitize(dsc_txt)}}/>
        <br/>
        </Fragment> : null
      ) 

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}
}

function LoadBrandSort(firma, base){
    const form = new FormData()
          //form.append('BaseNumber', setup.base);
          form.append('BaseNumber', base);
          form.append('lang', setup.lang);
          form.append('firma', firma);
    const MyInit = { 
                method: 'POST',
                body: form
                };
          fetch(setup.domen+setup.folder+'brand', MyInit)
          .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error(response.text()+'Something went wrong ...');
              }
          })

          .then(data => {   
                            //dispatch({type:'LOAD_CONTENT', payload:data});
                            if( data.brand.ttl === '' ){ setNotFound(true); }
                            setContent({...content, loading:false, data:data});
                         })
          .catch(error => {
               setContent({loading:true, error:error, data:null});
           });
}

}; 



