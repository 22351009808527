import React, { useState, useEffect, Fragment, useMemo } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import Base64 from "../Base64"
import ItmGallery from "../ItmGallery"
import Loader from "../Loader"
import GetStepNavigation from "../StepNavigation_MOB"
import ScrollUp from "../ScrollUp"
import NotFound from "../NotFound"

import {
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";


export default function GetDprtMobile(props){

//const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);
const brands = useSelector(state => state.brands.ttl);

let mnu = [];
mnu['ttl'] = 'none'

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [mnulist,setMnulist] = useState({mnu:mnu});
const [notFound, setNotFound] = useState(false);

  let { base, id } = useParams();
  let { path, url } = useRouteMatch();

useEffect(() => {
  window.scroll(0, 0);
  GetDprt(id, bases[base]); 
}, [id] );

//var nURL = url.slice(0, url.indexOf(id)-1);

const ShowRender = useMemo(() => <Main/>, [mnulist.mnu['ttl']]);

if(notFound){return <Fragment>
                      <h2 className="itmttl">Что здесь?</h2>
                      <NotFound/>
                    </Fragment>
}
if(AllIs.error){
        return <p>DPRT: {AllIs.error.message}</p>
    } 

  if(AllIs.loading){  return <Loader/> } 

//console.log('DPRT:', url);
//var ttl = !brands[mnulist.mnu['dprt'][0].ttl] ? mnulist.mnu['upttl']+' '+mnulist.mnu['ttl'] : mnulist.mnu['ttl'];
let upupid = mnulist.mnu['upupid'];
let upDprtTTL = '';
if( mnulist.mnu['upupttl'] !== '.' ){
  if(!bases[upupid]){
    upDprtTTL = mnulist.mnu['upupttl']+' '+mnulist.mnu['upttl'].toLowerCase()+' '+mnulist.mnu['ttl'] 
  } else {
    upDprtTTL = !brands[mnulist.mnu['dprt'][0].ttl] ? mnulist.mnu['upttl']+' '+mnulist.mnu['ttl'] : mnulist.mnu['upttl']+' '+mnulist.mnu['ttl'].toLowerCase();
  }
} else {
  upDprtTTL = mnulist.mnu['ttl'];
}
var ttl = upDprtTTL;

var mttl = ttl + ' — купить в Дельта Инжиниринг';
var metDsc = mnulist.mnu['meta_dsc'] !=='.'? mnulist.mnu['meta_dsc'] : mttl;
var keywords = mnulist.mnu['kwrd'] !=='.'? mnulist.mnu['kwrd'] : ttl;
var title = mnulist.mnu['title'] !=='.'? mnulist.mnu['title'] : mttl;
const isBrand = name =>{
  if(brands[name] && brands[name] !=='') {return true } else {return false}
}
document.title = title;
return(
  <Fragment>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{ title }</title>
          <link rel="canonical" href={setup._domen+url }/>
          <meta name="description" content={metDsc}/>
          <meta name="keywords" content={keywords}/>
      </Helmet>
      <h1 className="itmttl">{ ttl }</h1>
      <GetStepNavigation/>
      <ShowContent content={mnulist.mnu['html']}/>
      <ul className={brands[mnulist.mnu['dprt'][0].ttl]?'brands':'tag'}>
        {
          mnulist.mnu['dprt'].map((i, key) =>
            i.vmode === 'dprt'?
                      <li key={key}><Link to={`/${base}/${id}/${i.id}`}>
                        {isBrand(i.ttl)?
                          brands[i.ttl].img.typ ==='svg'? <img height="25" src={'/svg/brands/'+brands[i.ttl].img.src} alt={ttl+' '+i.ttl} title={ttl+' '+i.ttl}/> : <Fragment>{i.ttl}</Fragment>
                        : <Fragment>{i.ttl}</Fragment>
                        }
                        </Link>
                      </li>
            : null    
            //<li key={key}><Link to={ i.vmode === 'dprt' ? `${url}/${i.id}` : `${nURL}/${id}/${i.htmlttl}~${i.id}.html` }></Link>
            //<span><b>{i.man} {i.ttl}</b>—{i.pfx}</span>
          )
        }
      </ul>      
    {ShowRender}
  </Fragment>
);

function Main(){
  if(mnulist.mnu['ttl'] === 'none'){ return null } 
  return(
    <Fragment>
      <ItmGallery/>
      <ScrollUp/>
    </Fragment>
  )
}

function ShowContent(props){
    let dsc_txt = Base64.decode(props.content);
    if (dsc_txt ==='nodata') {return null}
    return( 
            dsc_txt !== '.'? <Fragment>
            <br/>
            <div className="dprtdsc" dangerouslySetInnerHTML={{ __html: sanitize(dsc_txt)}}/>
            <br/>
            </Fragment> : null
          ) 
    
    function sanitize(html) {
      var doc = document.createElement('div');
      doc.innerHTML = html;
      return doc.innerHTML;
    }
    }

function GetDprt(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'get-d-list', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
      if(data.qwn > 0 ) {
        //dispatch({type:'SET_CUR_ID', payload:data.upid});
        for (var i = 0; i < data.mnulist.length; i++) {
          data.mnulist[i].ttl = Base64.decode(data.mnulist[i].ttl);
          data.mnulist[i].pfx = Base64.decode(data.mnulist[i].pfx);
        }
      } else {
        setNotFound(true); 
      }

      let mlist =  data.qwn > 0 ? data.mnulist : [];
      //dispatch({type:'SET_MENU_LIST', payload:{idx:base, mnulist:mlist}});
      let m = mnulist.mnu;
      m['dprt'] = mlist;
      m['ttl'] = data.ttl;
      m['upttl'] = data.upttl;
      m['upupttl'] = data.upupttl;
      m['upupid'] = data.upupid;
      m['meta_dsc'] = Base64.decode(data.meta_dsc);
      m['kwrd'] = Base64.decode(data.kwrd);
      m['title'] = Base64.decode(data.title);
      m['dsc'] = Base64.decode(data.dsc);
      m['html'] = data.html;

      setMnulist({mnu:m});
      //dispatch({type:'SET_COUNT', payload:5});
      //setDprt({loading:false, error:null});
      setAllIs({loading:false, error:false});
      //GetMenu(data.upid, base); 
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 
