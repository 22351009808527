import React, { useState, useEffect, Fragment } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'

import Get_Alt_DPRT from "../DPRT/Get_Alt_DPRT"
import GetDprtMobile from "../DPRT/GetDprtMobile"

import GetNews from "../GetNews"
import GetNovinki from "../GetNovinki"

import "./navblk.css"
import PopularDeps from "../PopularDeps"

import {
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Loader from '../Loader';


export default function AltCatalogue(){

const setup = useSelector(state => state.setup);
const meta = useSelector(state => state.meta);
const tags = useSelector(state => state.tags.tags);
const ttl = useSelector(state => state.tags.ttl);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [mnulist,setMnulist] = useState([]);
const [result,setResult] = useState(null);

  let { tag, id } = useParams();
  let { path, url } = useRouteMatch();
  let history = useHistory();

useEffect(() => {
  window.scroll(0, 0);
  GetDATA('catmenu', bases['info']);
}, [] );


if(AllIs.error){
        return <p>MAIN APP: {AllIs.error.message}</p>
    } 
if(AllIs.loading){ return <Loader/> } 

const CatMenu = (props) => (
	<ul className="tag">
	  {
	    props.list.mnu.map((i, id) => <li key={id}><Link to={'/tag/'+i.id}>{i.ttl}</Link></li>)
	  }   
	</ul>
)

var title = meta.ttl;

//console.log('CAN', url);
if(url !=='/'){ title = ttl[tag]; }
document.title = title;
return (
<Fragment>
  <Helmet>
  <meta charSet="utf-8" />
  <title>{ title }</title>
  <meta name="description" content={meta.dsc}/>
  <meta name="keywords" content={meta.kwrd}/>
  <link rel="canonical" href={setup._domen+url}/>
  </Helmet>
<div className={tag?id?"item pages":"item pages":"index pages"}>
        {
       // tag? <button className="btn back" onClick={() => GoBack()}>{'<'}</button> :null
      }
      {
      id? <Fragment>
            <GetDprtMobile/>
          </Fragment>
       :
       tag? <Fragment>
              <h1 className="itmttl">{ title }</h1>
              <br/><br/>
              <Get_Alt_DPRT tag={tags[tag]}/>
            </Fragment>
       :
       <Fragment>
         <h1 className="itmttl">КАТАЛОГ</h1>
         <div className="navblk">
           <a className="btn nav" href="#news">НОВОСТИ</a>
           <a className="btn nav" href="#novinki">НОВИНКИ</a>
           <a className="btn nav" href="/search">ПОИСК</a>
         </div>
         <br/><br/>
          <CatMenu list={mnulist}/>
        <hr/>
          <PopularDeps/> 
        <a name="news"/>
        <GetNews mode="topics"/>
        <a name="novinki"/>
        <GetNovinki mode="topics"/>
        <a name="phones"/>
       </Fragment>
    }
    
    <div>
       <div>
          {result !== null ? <ShowSearchResult result={result}/> : null}
        </div>
    </div>
</div>
</Fragment>
);

function GoBack(){
  history.goBack();
}

function handleSearch(event){

	let mnu = mnulist.slice();
  //var t = new (Date()).getTime();

    var searchQuery = event.target.value.toLowerCase();
    
	var FilteredMenuList0 = mnu[0].filter((el) => {
	  var searchValue = el.ttl.toLowerCase();
	  return searchValue.indexOf(searchQuery) !== -1;});


    let  m  = mnulist.mnu.slice();
    m[0] = FilteredMenuList0;

    setMnulist({mnu:m});
    if(searchQuery !== '') {GetSearch(1,searchQuery);} else {
      setResult(null);
    }

};

function ShowSearchResult(props){

const result = props.result;

    return(
    result.itm ? !result.itm.id ? result.itm.map((i, key) => 
      <a href={i.url}>
        <span>{i.pfx+' '+i.ttl}</span>
      </a>
      ) : <span>ХОББА</span> : null
    )
}

function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-alt-menu'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
      setMnulist(data);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}


function GetSearch(base, filtr){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('filtr', filtr);
      
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'site-search.php', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
   
      setResult(data);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}


}