import reduceReducers from 'reduce-reducers';
import content from "./content";
import menu from "./menu";
import site from "./site";
import comments from "./comments";
import basket from "./basket";


//const rootReducer = combineReducers({ setup, content, menu, app });

const initialState = {

	curid:'saa',
	count:0,
	
    comnt_load_func:null,
    
    navbases:null,
	brands:null,
	tags:[],

    valuta: [],
    mnulist:[],
    dprt:[],
	content:[],
	kurs:[],
	minprc:0,
	basket:new Map(),
	bqwn: 0,
	btotal:0,
	meta:{ttl:'', dsc:'', kwrd:''},
	//meta:null,
    comments:{qwn:0, itm:[]},
    orders:{qwn:0, itm:[]},
    vConfig:{
    		vmode:'aaa',
    		config:[]
    },
	setup:{
			domen: '/',
			//domen: 'http://dotcher/',
			_domen: 'https://indelta.ru',
			imgdomen:'/pics',
			folder: "api/",
		    lang: 'ru/',
		    base:1,
		    bases:5,
			smode:true,
		    navid: []
	}
  	
};


const rootReducer = reduceReducers( initialState, site, menu, comments, basket );

export default rootReducer;