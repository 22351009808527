import React from 'react';

const Loader = () => {
    return (
        <div style={{"fontSize":"7px"}} className="_3KHIQ _8XiJu _2h_fa">
          <div className="_1XybS"/><div className="_1XybS _3FRxt"/><div className="_1XybS _1o7ne"/>
        </div>
    )
}

export default Loader