import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import "./css/tag.css"

export default function Tags(){

    const list = useSelector(state => state.tags.list);
    const tags = useSelector(state => state.tags.tags);
    const ttl = useSelector(state => state.tags.ttl);

return(
	<ul className="tag">
	  {
	    list.mnu.map((i, key) => i.onidx ==='y'? <li key={key}>
            <Link to={'/tag/'+i.id}>{i.ttl}</Link>
            </li>:null
            )
	  }  
    {
     // console.log('TAG RENDER')
    } 
	</ul>
)
}