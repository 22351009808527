import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import Base64 from "./Base64"
import Loader from "./Loader"
import './css/novinki.css'


export default function GetNews(props){

//const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [data,setData] = useState([]);

//console.log('COUNT:',count);

const id = "novinki";
const base = 'info';

useEffect(() => {
  GetDATA(id, bases[base]); 
}, [id] );

if(AllIs.error){
        return <p>INFO BLOCK: {AllIs.error.message}</p>
    } 

if(AllIs.loading){
        return( <Loader/> )
} 


const m = data.n;
const nt = m.novinki.itm.slice(0,4);
const nn = m.novinki.itm.slice(0,10);
//console.log('SLICE', m.news.itm.slice(1,4));

if(props.mode === "topics"){
return(
 <div className="infoblock">
  <h2>{Base64.decode(m.ttl)}</h2>

        {
        //m.news.itm.map((i, key) => 
        nt.map((i, key) => 
          <div key={key} className="novinki">
            <a href={Base64.decode(i.link)}>{i.man+' ' + Base64.decode(i.ttl)+' — '+Base64.decode(i.pfx)}</a>
            <div>
            <a href={Base64.decode(i.link)}>
              <img height="100" src={setup.imgdomen+'/'+Base64.decode(i.img).slice(0, Base64.decode(i.img).indexOf('.jpg'))+'_sm.jpg'}/>
            </a>
              {Base64.decode(i.dsc)}
            </div>
            <div className="cleaner"/>
          </div>)
      }
  
  </div>
)
}


return(
  <div>
  <h2>{Base64.decode(m.ttl)}</h2>

        {
        //m.news.itm.map((i, key) => 
        nn.map((i, key) => 
          <div key={key} className="novinki">
            <a href={Base64.decode(i.link)}>{Base64.decode(i.ttl)+' — '+Base64.decode(i.pfx)}</a> 
            <div className="container">
              <a href={Base64.decode(i.link)}>
                <img height="100" src={setup.imgdomen +'/'+Base64.decode(i.img).slice(0, Base64.decode(i.img).indexOf('.jpg'))+'_sm.jpg'}/>
              </a>
              <div dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(i.dsc))}}/>
            </div>
            <hr/>
          </div>)
      }
  
  </div>
);



function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}

function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-novinki'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    
      setData(data);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 


