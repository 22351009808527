import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { render } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux';
import {useWindowSize} from 'react-use';
import Loader from "../Loader";
import {useCss, useTimeoutFn} from 'react-use';
import './slider.css';
import { Link } from "react-router-dom";
import Base64 from "../Base64";

var slider = null;
var dots = [];
var rend = 0;
var MaxSlides = 0;
var RefEl_W = 1200;
var slHeiht = 303;
var imgObj = [];
var rnum = 0;

export default function MainAdSlider(){
    const setup = useSelector(state => state.setup);
    const [ActiveSlide, setActiveSlide] = useState(0);
    const [ActiveCSS, setActiveCSS] = useState('hidden');
    const [AllIs, setAllIs] = useState({loading:true, error:null});
    
    var offset = 0;
    
    var RefElement = document.querySelector("div[class='pages']");
    
    //var RefElement = document.getElementById('preview');
    
    const {width, height} = useWindowSize();

    useEffect(() => { 
        window.innerWidth >= 1200?
        RefEl_W = 1200-40 : RefEl_W = window.innerWidth-40;
        slHeiht = RefEl_W/4+3;
    }, [] );  


    function nextBanner(){
        setActiveCSS('hide');
        p_reset();
    }

    function SetBanner(num){
    setActiveCSS('hide');
    r_reset();
    rnum = num;
    }

    function ShowtBanner(){
        reset();
        p_cancel();
        r_cancel();
        setActiveCSS('show');
        dots[ActiveSlide] = 'dot';
        dots[rnum] = 'dot active';
        setActiveSlide(rnum);
        }

    const [isReady, cancel, reset] = useTimeoutFn(nextBanner, 5000);
    const [p_isReady, p_cancel, p_reset] = useTimeoutFn(ShowNextBanner, 400);
    const [r_isReady, r_cancel, r_reset] = useTimeoutFn(ShowtBanner, 400);

    function ShowNextBanner(ev){
        reset();
        p_cancel();
        r_cancel();
        setActiveCSS('show');
        if(ActiveSlide < MaxSlides-1) { 
            dots[ActiveSlide] ='dot';
            dots[ActiveSlide+1] ='dot active';
            setActiveSlide(ActiveSlide+1);
        }
        else { 
            dots[ActiveSlide] ='dot';
            dots[0] ='dot active';        
            setActiveSlide(0);
        }        
        
    }

    useEffect(() => { 
        dots = ['dot active','dot','dot','dot','dot','dot','dot','dot','dot','dot','dot','dot','dot','dot'];
        GetSliders(); 
    }, [] );

    useEffect(() => { 
    if(!AllIs.loading){
            RefEl_W = RefElement.clientWidth-40;
            slHeiht = RefEl_W/4+3;
            //console.log("REFEL:",slHeiht);
        }
    }, [width] );   

//offset = slHeiht-(ActiveSlide+1)*slHeiht;

const slBanFeed = useCss({
    'position': 'relative',
         'top': offset+'px',
         'transition': '.5s ease'
    });

const slBanPanel = useCss({
        'width': RefEl_W + 'px',
        'height': slHeiht - 2 + 'px',
        'overflow': 'hidden'
    });




if(AllIs.loading){ return ( 
                            <div className="Slider">
                                <div className="SlPlaceholder">
                                    <Loader/>
                                </div>
                                <ul className="SliderNav">
                                    <li className="dot active"></li>
                                </ul>
                            </div>
                          )
} 

//console.log("RENDER:",rend++);
//console.log("REFEL:",RefEl_W);
return(
  MaxSlides > 1 ?
        <div className="Slider">
            <div className={slBanPanel}>
                <div className={slBanFeed}>
                    {
                    <Link to={slider[ActiveSlide].link} title={Base64.decode(slider[ActiveSlide].ttl)}> 
                        <img src={imgObj[ActiveSlide].src} width={RefEl_W} height={RefEl_W/4} title={Base64.decode(slider[ActiveSlide].ttl)} alt={Base64.decode(slider[ActiveSlide].ttl)} className={ActiveCSS}/>
                    </Link>
                    }
                </div>
            </div>
            <ul className="SliderNav">
                    {
                    slider.map((i, key) =>
                        <li className={dots[key]} onClick={()=>SetBanner(key)}></li>        
                        )
                    }                
            </ul>
        </div>
        :
        <div className="Slider">
          {cancel()}
            <div className="slBanPanel">
                <div className={slBanFeed}>
                    {
                        <Link to={slider.link} title={Base64.decode(slider.ttl)}> 
                            <img src={'/'+slider.imgs.src}  width={RefEl_W} height={RefEl_W/4} title={Base64.decode(slider.ttl)} alt={Base64.decode(slider.ttl)}/>
                        </Link>
                    }
                </div>
            </div>
        </div>        
    )

    function GetSliders(){
        cancel(); p_cancel(); r_cancel();

      const form = new FormData()
      form.append('BaseNumber', 4);
      form.append('lang', setup.lang);
    
      const MyInit = {method: 'POST', body: form};
    
      fetch(setup.domen + setup.folder + 'slider', MyInit)
        .then(response => {
          if (response.ok) {
              //console.log('TS:', response.text());
            return response.json();
          } else {
            throw new Error(response.text() + 'Something went wrong ...');
          }
        })
        .then(data => {
          if(data.qwn > 0 ) {
            MaxSlides = data.qwn;
            slider = data.itm;

            slider.map((i, key) =>{
                imgObj[key] = new Image(1200,300);
                imgObj[key].src = 'https://indelta.ru/'+i.imgs.src;
                imgObj[key].title = Base64.decode(i.ttl);
                imgObj[key].decode();
            });
            reset();
            setActiveCSS('show');
            setAllIs({loading:false, error:false});
          }
        })
        .catch(error => {
          setAllIs({loading:true, error:error});
        }
        );
    }


}