import React, { useState, useEffect, useMemo, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux'


import {
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Loader from './Loader';


//ВЫВОД ГАЛЛЕРЕИ DPRT С ЦЕНАМИ. ДЕСКТОП ВЕРСИЯ.

export default function ItmGallery(props){
  let { tag, base, id } = useParams();
  let { path, url } = useRouteMatch();

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const kurs = useSelector(state => state.kurs);
const bases = useSelector(state => state.navbases.nums);
const brands_by_ttl = useSelector(state => state.brands.ttl);


const [AllIs, setAllIs] = useState({loading:true, error:null});
var [readydata,setData] = useState({itm:'none'});
var [itemList,setItemList] = useState('none');
const [query,setQuery] = useState('');
const [result,setResult] = useState('');


useEffect(() => {
  GetDATA(bases[base], id);
}, [props.id] );


useEffect(() => {
  setItemList(readydata.itm); 
}, [readydata.itm] );

useEffect(() => {
  if(query !== '' && query.length > 2) {
     // GetSearch(query)
  } else {
    setResult('');
  }
}, [query]);



const Show_ItmList = useMemo(() => ShowItmList(itemList), [itemList, AllIs.loading]);


var nURL = url.slice(0, url.indexOf(id)-1);

if(AllIs.error){
        return <p>DPRT: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return <Loader/> } 

return(
  <Fragment>
    <input placeholder='Поиск'  type="text" className="filter" onChange={handleSearch} autoCorrect="off" autoComplete="off"/>
    {Show_ItmList}
  </Fragment>
);


function ShowPrc(props){
  if(brands_by_ttl[props.man].prc ==='y'){
    if(props.zak === 'y'){

    var prc = props.prc;
    if(kurs[props.log] !== '1'){
      prc = prc*parseFloat(kurs[props.log].replace(',','.'));
    }
    
    var rub = new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(prc);
    var digit = rub.slice(0, rub.indexOf(','));
    var kopeyki = rub.slice(rub.indexOf(','));

        return (
          <div className='dminPrice'>
            <span className='from'>ОТ </span>
              <span className='rub'>{digit}</span>
              <span className='kop'>{kopeyki}</span>
          </div>
        )
      }
    } 
  return (null)
}


function handleSearch(event){
  let mnu = readydata.itm.slice();
  var searchQuery = event.target.value.toLowerCase();
  var FilteredList = mnu.filter((el) => {
    var searchValue = el.pfx.toLowerCase() +' '+ el.ttl.toLowerCase() +' '+ el.man.toLowerCase();
    return searchValue.indexOf(searchQuery) !== -1;});
    let m = FilteredList;

    if( FilteredList.length > 0 ){
      setItemList(m);
    }
      setQuery(event.target.value);
}

function ShowItmList(itm){
if(itm === 'none'){return null}
return(
<ul className="gallery">
{
  itm.length?
    itm.map((i, key) =>
      <li key={key}>
        {
        //i.zak==='y'? 'ZAAAAK':null
        }
        <Link to={i.url } title={i.pfx+' '+i.man+' '+i.ttl}>
          <ShowImage src={i.img} ttl={i.pfx+' '+i.man+' '+i.ttl}/>
        </Link>
          <p>
          <Link to={i.url } title={i.pfx+' '+i.man+' '+i.ttl}>
            {i.pfx}<br/>{i.man+' '+i.ttl}
          </Link>
          </p>
          <ShowPrc prc={i.prc} man={i.man} log={i.log} zak={i.zak}/>
      </li>)
       :
       <li>
        <Link to={ itm.url } title={itm.pfx+' '+itm.man+' '+itm.ttl}><ShowImage src={itm.img} ttl={itm.ttl}/></Link>
          <p>
            <Link to={ itm.url } title={itm.pfx+' '+itm.man+' '+itm.ttl}>
              {itm.pfx}<br/>{itm.man+' '+itm.ttl }
            </Link>
          </p>
          <ShowPrc prc={itm.prc} man={itm.man} log={itm.log} zak={itm.zak}/>
       </li>
}
</ul>
)
}


function ShowImage(props){
  const img = props.src;
  const imgttl = props.ttl;
  const mode = '_sm';
  const domen = setup.imgdomen;

  const ext = img.slice(-4); 
  const path = img.slice(0, -4);

  return <img src={domen+path+mode+ext} height="120" alt={props.ttl} title={props.ttl}/>

}


function GetDATA(base, cid){
  const form = new FormData()
        form.append('base', base);
        form.append('lang', setup.lang);
        form.append('cid', cid);
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'pics', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
      setData(data.items);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 
