import React, { useState, useEffect, Fragment, useMemo} from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import Base64 from "./Base64"
import Loader from "./Loader"
import useMedia from "./js/useMedia"
import NotFound from "./NotFound"
import LightBox from './js/lb'
import './css/lightBox.css'

import {
  useParams,
  useRouteMatch
} from "react-router-dom";

import "./css/news.css"

var small = false;

export default function Show_NEWS(props){

//const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [notFound, setNotFound] = useState(false);

const [content, setContent] = useState({loading:true, error:null, data:null});

small = useMedia("(max-width:650px)");

  let { id } = useParams();
  let { path, url } = useRouteMatch();

//const id = "news";
const base = 'info';


useEffect(() => {
  GetDATA(id, bases[base]); 
  //LightBox('a.lbpic');
}, [id] );

useEffect(() => {
  LightBox('a.lbpic');
}, [content] );

//const main = useMemo(() => <Render mode={props.mode}/>, [content.data]);
const main = useMemo(() => <Render mode="page"/>, [content.data]);



if(notFound){return <NotFound/>}
if(content.error){ return <p>INFO BLOCK: {content.error.message}</p> } 
if(content.loading){ return <Loader/> } 

const itm = content.data;
let i = itm.news;
const title = Base64.decode(i.ttl);
document.title = title;



return( main )

function Render(props){
  return( props.mode === 'page'? RenderPage() : RenderBlock() )
}

function RenderPage(){
  
  return(
      <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="canonical" href={setup._domen+url}/>
            <meta name="description" content={title}/>
            <meta name="keywords" content={title}/>
          </Helmet>    
          
          <div className={small?"item pages":"pages pt50"}>
            <h1 className={small?"itmttl":null}>{Base64.decode(i.ttl)}</h1>
            <br/><br/>
              <ShowImages content={i} mode="_m"/>
              <span className="b red">{Base64.decode(i.date)}</span>  <span>{title}</span>
              <div className="news" dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(i.dsc))}}/>
          </div>
      </Fragment>
    )
}

function RenderBlock(){
  return(
    <Fragment>
      <div>
        RENDER BLOCK
      </div>
    </Fragment>
  )
}




function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}


function ShowImages(props){
  const dmn =setup.imgdomen+'/';
  const imgs = props.content.imgs.img;
  const imgttl = Base64.decode(props.content.ttl);
      return ( 
        imgs ?<Fragment>
          {
              imgs.length? 
                  imgs.map((i, key) => (
                    <a key={key} href={dmn+i.src} className="lbpic flLeft">
                        <img src={dmn+i.src.slice(0, i.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={imgttl} title={imgttl}/>
                    </a>)) 
                  :
                    <a href={dmn+imgs.src} className="lbpic flLeft">
                        <img src={dmn+imgs.src.slice(0, imgs.src.indexOf('.jpg'))+props.mode+'.jpg'} alt={imgttl} title={imgttl}/>
                    </a>
           }
              </Fragment>
              : null
              )
}

function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-itm-news'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
      if( data === '' ){ setNotFound(true); } 
      if( data.news.dsc === 'Lg==') {setNotFound(true);}
      
      setContent({loading:false, error:null, data:data});
      })
  .catch(error => {
    setContent({loading:true, error:error, data:null});
   });
}

} 


