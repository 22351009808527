import React from 'react';
import { useSelector } from 'react-redux'
import Loader from "../Loader"


export default function ShowMinPrc (props) {
    const otPrc = useSelector(state => state.minprc);
    // console.log('ShowMinPrc', otPrc); 
    var rub = new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(otPrc);
    var digit = rub.slice(0, rub.indexOf(','));
    var kopeyki = rub.slice(rub.indexOf(','));

    if(props.mode ==='showprc'){
        if(otPrc === 0) {
          return (<div className='minPrice loading'><div></div><Loader/></div>)
            } else {
              return (
                    <div className='minPrice'>
                    <span className='from'>ОТ </span>
                      <span className='rub'>{digit}</span>
                      <span className='kop'>{kopeyki}</span>
                    </div>
                  )
          }
}

   if(props.mode ==='call'){
    return (
      <noindex>
      <div className='minPrice'>
       <span className='from'>цена </span>
        <span className='rub'>ПО ЗАПРОСУ</span>
      </div>
      </noindex>
    )}
    
      return (
        <noindex>
          <div className='minPrice'>
          <span className='rub'>ЗВОНИТЕ!</span>
          </div>
        </noindex>
      )


 }