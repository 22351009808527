import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "./Base64"
import Loader from "./Loader"

import "./css/news.css"

export default function GetNews(props){

//const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [data,setData] = useState([]);

//console.log('COUNT:',count);

const id = "news";
const base = 'info';


useEffect(() => {
  GetDATA(id, bases[base]); 
}, [id] );



if(AllIs.error){
        return <p>INFO BLOCK: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return <Loader/> }


const m = data.n;
const nn = m.news.itm.slice(0,14);
const nt = m.news.itm.slice(0,4);
//console.log('SLICE', m.news.itm.slice(1,4));

if(props.mode === "topics"){
return(
  <div className="infoblock">
  <h2>{Base64.decode(m.ttl)}</h2>

        {
        //m.news.itm.map((i, key) => 
        nt.map((i, key) => 
        
          <div key={key} className="novinki">
            
          <span className="b">{Base64.decode(i.date)+' '}</span> 
          <a href={'/news/'+i.id}>{Base64.decode(i.ttl)}</a>
          </div>)
      }
  
  </div>
)
}

return(
  <div style={{'padding':'0px 20px'}}>
  <h2>{Base64.decode(m.ttl)}</h2>

        {
        //m.news.itm.map((i, key) => 
        nn.map((i, key) => 
          <div key={key} className="white f10">
          <span className="topic">{Base64.decode(i.date)+' / '}
          <a href={'/news/'+i.id}>{Base64.decode(i.ttl)}</a></span>
          <div className="news" dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(i.top))}}/>
          </div>)
      }
    </div>
);





function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}

function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-news'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    
      setData(data);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 


