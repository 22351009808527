import React, { Fragment } from 'react';

export default function NotFound(){
    return(
      <Fragment>
      <p className="tac b f10">ВЫ ПОПАЛИ НА НЕСУЩЕСТВУЮЩУЮ СТРАНИЦУ</p>
      <div className="notfound">404</div>
      <p className="tac b">НИЧЕГО НЕ НАЙДЕНО</p>
      </Fragment>
    )
  }