import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "../Base64"

import {
  BrowserRouter as Router,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Loader from '../Loader';

//var bases = [];

var mList =[];

export default function GetMenuMobile(props){

const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [mnulist,setMnulist] = useState({mnu:[]});
const [open, setOpen] = useState(false);
 
 
  let { base, pid, id } = useParams();
  let { url } = useRouteMatch();

var npID = pid != null ? pid : base;

useEffect(() => {
  GetMenu(npID, bases[base]); 
}, [npID] );

var nURL = pid == null ? (id== null ? url : url.slice(0, url.indexOf(id)-1)) : url.slice(0, url.indexOf(pid)+pid.length);
//var nURL = url.slice(0, url.indexOf(id)-1);


if(AllIs.error){
        return <p>MENU: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return <Loader/> } 

const toggle = () => open ? setOpen(false) : setOpen(true)

//console.log('MAIN COUNT:',count);
const man = props.man;

return(
<Fragment>
<div className={open? 'itmMenu open': 'itmMenu'} onClick={toggle}>
    {
    !open?<img src="/svg/itm-menu.svg" height="25" width="51"/>
    :<img src="/svg/itm-menu-open.svg" height="25" width="51"/>
    }
</div>
{
    open? 
    <Fragment>
    <ul className="mobMenu" onClick={toggle}>
        {
        mnulist.mnu['menu'].map((i, key) =>
        <li key={key} className={ i.id !== id ? null : "selected" }>
        <Link to={ i.vmode === 'dprt' ? `${nURL}/${i.id}` : `${nURL}/${i.htmlttl}-${i.id}.html` }>
        {
            i.vmode === 'dprt' ? 
            <span>{i.ttl}</span>
            : 
            <span><b>{man+' '+i.ttl}</b>—<span>{i.pfx}</span></span>
        }
        </Link>
        </li>)
        }
    </ul>
    <div key="overlay" className="overlay" onClick={toggle}/>
    </Fragment>
    :null
}
</Fragment>
);


function GetMenu(cid, base){
  //console.log("BASE:",base);
  const form = new FormData()
  form.append('BaseNumber', base);
  form.append('lang', setup.lang);
  form.append('CurID', cid);

  const MyInit = {method: 'POST', body: form};

  fetch(setup.domen+setup.folder+'get-m-list', MyInit)
    .then(response => {
      if (response.ok) {
        //console.log('TS:', response.text());
        return response.json();
      } else {
        throw new Error(response.text() + 'Something went wrong ...');
      }
    })
    .then(data => {
      //this.mnuUpID = data.upid;
      //let mnulist =  data.qwn > 0 ? data.mnulist : [{ttl:'тут нчиего нет', vmode:'', depth:'1', pbl:'y', id:'hz', chq:0}];

      if(data.qwn > 0 ) {
        for (var i = 0; i < data.mnulist.length; i++) {
          data.mnulist[i].ttl = Base64.decode(data.mnulist[i].ttl);
          data.mnulist[i].pfx = Base64.decode(data.mnulist[i].pfx);
        }
      }

      let mlist =  data.qwn > 0 ? data.mnulist : [];
      //dispatch({type:'SET_MENU_LIST', payload:{idx:base, mnulist:mlist}});
      let m = mnulist.mnu;
      m['menu'] = mlist;
      if(data.mnulist[0]) data.mnulist.map((i, key)=> mList[i.id] = i);
      setMnulist({mnu:m});
      setAllIs({loading:false, error:false});
      //dispatch({type:'SET_COUNT', payload:5});
      
    })
    .catch(error => {
      //MenuIs[idx] = {loading:false};
      //setMenuIs(MenuIs);
      setAllIs({loading:true, error:error});
    }
    );
}
} 
