import React, { useMemo, Fragment, useRef, useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import {useCss} from 'react-use';
import { useTimeoutFn } from 'react-use';

import Base64 from "../Base64"
//import GetMENU from "../GetMENU"
import GetMenuTabbed from "../Mobile/GetMenuTabbed"
import GetStepNavigation from "../StepNavigation"
import AddComment from "../AddComment"
import GetPriceCALL from "../GetPriceCALL"
import GetPrice from "../GetPrice"
import ShowComments from "../Comments/ShowComments"
import ScrollUp from "../ScrollUp"
import ShowMinPrc from "./ShowMinPrice"

import { useRouteMatch } from "react-router-dom";

import '../css/content.css'
import './tabs.css'

//import * as $ from 'jquery';
//import '../css/jquery.lightbox-0.5.css'

import LightBox from '../js/lb'


export default function DeskTop(props){

const setup = useSelector(state => state.setup);
//const otPrc = useSelector(state => state.minprc);

const brands_by_ttl = useSelector(state => state.brands.ttl);
const [display, setDisplay] = useState({dsc:'unset', prc:'unset', cmt:'unset'});
const [menu_width, setWidth] = useState('300px');
const [MinPrc, setMinPrc] = useState(0);


const ShowPictures = useMemo(() => <ShowImages content={props.data} mode='_m'/>, [props.data]);
const ShowDSC = useMemo(() => <ShowContent content={props.data.dsc_txt}/>, [props.data.dsc_txt]);
const MainRender = useMemo(() => <Main domen={setup._domen}/>, [props.data.dsc_txt]);

const imgsLng = props.data.imgs.img? props.data.imgs.img.length : 1;
const position = imgsLng>4? 'unset':'sticky';

useEffect(() => {
    setDisplay({dsc:'unset', prc:'unset', cmt:'unset'});
    setWidth('300px');
    LightBox('a.lbpic');
  }, [props.data.dsc_txt] );
  
let { url } = useRouteMatch();

const price = useRef(null);

const stick = useCss({
  'position': position,
  'top':'45px'
});

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const description = useCss({  display: display.dsc });
const prices = useCss({  display: display.prc });
const comments = useCss({  display: display.cmt });

const executeScroll = () => scrollToRef(price)


const itm = props.data;
var t= Base64.decode(itm.t);
var p = Base64.decode(itm.pfx);

var ttl = itm.man+' '+t+' — '+p;
var mttl = ttl;

let mDsc = Base64.decode(itm.meta_dsc);
var metDsc = mDsc !=='nodata'? mDsc : itm.man+' '+Base64.decode(itm.t)+' — '+Base64.decode(itm.sm_dsc);

let genKwrds = `${itm.man}, ${t}, ${p}`;
let kwrds = Base64.decode(itm.kwrd);
var keywords = kwrds ==='nodata'? genKwrds : `${genKwrds}, ${kwrds}`;
document.title = mttl;


return(
 <Fragment>
  { MainRender }
 </Fragment>
)


function Tabs(){
    const [active, setActive] = useState({dsc:'active', prc: null, cmt: null});
    const show_Description =() => {
        setDisplay({dsc:'unset', prc:'unset', cmt:'unset'});
        setActive({dsc:'active', prc: null, cmt: null});
    }
    const show_Prices =() => {
        setDisplay({dsc:'none', prc:'unset', cmt:'none'});
        setActive({dsc:null, prc: 'active', cmt: null});
    }
    const show_Comments =() => {
        setDisplay({dsc:'none', prc:'none', cmt:'unset'});
        setActive({dsc:null, prc: null, cmt: 'active'});
    }

    return(
        <ul className='tabs'>
          <div className='tbslft'>
            <li className={active.dsc} onClick={show_Description}>ОПИСАНИЕ</li>
            {
              itm.zak !== "n"? <li className={active.prc} onClick={show_Prices}>ЦЕНЫ</li> : null
            }
            <li className={active.cmt} onClick={show_Comments}>КОММЕНТАРИИ</li>
          </div>
          <div className='tbsrght'>
            {itm.zak !== "n"? 
                                  brands_by_ttl[itm.man].prc ==='y'?
                            <ShowMinPrc mode='showprc' prc="0"/>
                            :
                            <ShowMinPrc mode='call'/>
                            :
                            <ShowMinPrc mode='empty'/>
            }
          </div>
        </ul>
    )
}

function Main(props){
return(
  <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{mttl}</title>
            <meta name="description" content={metDsc}/>
            <meta name="keywords" content={keywords+', купить в Челябинске, Челябинск'}/>
            <link rel="canonical" href={props.domen+itm.url}/>
        </Helmet>
  
          <div className="pages">
                  <GetStepNavigation/>
                  <h1>{ttl}</h1>
                  <GetMenuTabbed man={itm.man}/>
                  <table className="main">
                    <tbody>
                    <tr>
                        <td className="content">
                        {Tabs()}
                        {
                          itm.snyat === "y"? <p className="retired b tac">СНЯТ С ПРОИЗВОДСТВА!</p> : null
                        }

                          {ShowDSC}

                        <div ref={price} className={prices}>
                        {
                         itm.zak !== "n"? 
                                brands_by_ttl[itm.man].prc ==='y'?
                                          <GetPrice
                                              id={itm.id}
                                              ttl={{p:Base64.decode(itm.pfx), t:Base64.decode(itm.t), v:itm.man}}
                                              vmode={itm.vmode}
                                              vendor={brands_by_ttl[itm.man].id}
                                              modif={itm.modif}
                                              prc={itm.prc}
                                              valuta={itm.log}
                                          /> 
                                          : 
                                          <GetPriceCALL
                                              id={itm.id}
                                              ttl={{p:Base64.decode(itm.pfx), t:Base64.decode(itm.t), v:itm.man}}
                                              vmode={itm.vmode}
                                              vendor={brands_by_ttl[itm.man].id}
                                              modif={itm.modif}
                                              prc={itm.prc}
                                              valuta={itm.log}
                                          />
                                :null
                        }
                        </div>
                        <div className={comments}>
                          <h3>ВОПРОСЫ, ОТЗЫВЫ И КОММЕНТАРИИ</h3>
                            <ShowComments/>
                        </div>
                        </td>
                      <td className="pictures">
                      <div className={stick}>
                            {ShowPictures}
                            <AddComment/>
                          </div>
                      <ScrollUp/>
                      </td>
                    </tr>
                  </tbody></table>
          </div>
</Fragment>
)
}

function ShowImages(props){
//if(content.loading) { return <Loader/> }
  //let oimg[] = new Image();
  const dmn=setup.imgdomen+'/';
  const imgs = props.content.imgs;
  const imges = Base64.decode(props.content.imges);
  const imgttl = props.content.ttlpfx+'-';
  if(imgs === '' ){ 
   const ext = imges.slice(-4); 
   const impath = imges.slice(0, -4);
   return <a href={setup.imgdomen+imges} className="lbpic" title={ttl}><img src={setup.imgdomen+impath+props.mode+ext} data-original={'https://indelta.ru'+setup.imgdomen+impath+ext} alt={ttl} title={ttl}/></a>
  } else {
    return ( imgs.img[0] ? 
                imgs.img.map((i, key) => (i.p === 'y' ? 
                  <a key={key} href={dmn+imgttl+i.src} className="lbpic" title={ttl}>
                      <img src={dmn+imgttl+i.src.slice(0, i.src.indexOf('.jpg'))+props.mode+'.jpg'} data-original={'https://indelta.ru'+dmn+imgttl+i.src.slice(0, i.src.indexOf('.jpg'))+'.jpg'} alt={ttl} title={ttl}/>
                  </a>: null)) 
                :
                  <a href={dmn+imgttl+imgs.img.src} className="lbpic" title={ttl}>
                      <img src={dmn+imgttl+imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg'))+props.mode+'.jpg'} data-original={'https://indelta.ru'+dmn+imgttl+imgs.img.src.slice(0, imgs.img.src.indexOf('.jpg'))+'.jpg'} alt={ttl} title={ttl}/>
                  </a>
            )

        }

}

function ShowContent(props){
//if(content.loading) { return <Loader/> }
const dsc_txt = props.content;
//$(() => { $('a.lbpic').lightBox(); })
return( 
    <div id="cntWrp" className={description} dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(dsc_txt))}}/>
) 

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}
}


 
}; 



