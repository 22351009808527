import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'

export default function BasketQwn(props){

const bqwn = useSelector(state => state.bqwn);


return(
    <Fragment>
        {
            bqwn > 0? <span className="basketinfo">{bqwn}</span> : null
        }
    </Fragment>
)

}