import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch, connectAdvanced } from 'react-redux'
import Base64 from "./Base64"

import './css/popularDeps.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import Loader from './Loader';

export default function PopularDeps(){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);
const navbase = useSelector(state => state.navbases.itms);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [nav,setNav] = useState([]);


//console.log('COUNT:',count);



useEffect(() => {
  GetData(); 
}, [] );


if(AllIs.error){ return <p>STEP NAV: {AllIs.error.message}</p> } 
if(AllIs.loading){ return <Loader/> } 

return(
    <Fragment>
    <h2 className="pop">ПОПУЛЯРНЫЕ РАЗДЕЛЫ</h2>
        <ul className="populardeps">
            {
            nav.map((i,key) => 
                <li key={key}>
                    <Link to={i.url} title={i.ttl}>
                        <img src={`${setup.imgdomen}/${i.img.slice(0, i.img.indexOf('.jpg'))}_sm.jpg`} alt={i.ttl} title={i.ttl}/>
                        <p>{i.ttl}</p>
                    </Link>
                </li>
            )
            }
        </ul>
  </Fragment>
);


function GetData(){
  const form = new FormData()
        form.append('BaseNumber', 4);
        form.append('lang', setup.lang);
        form.append('CurID', 'popdeparts');
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'pupular', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    for (var i = 0; i < data.d.length; i++) {
       //   data.m[i].ttl = Base64.decode(data.m[i].ttl);
      }
      setNav(data.d);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 
