import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux'
import NotFound from "../NotFound"

import {
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import Loader from '../Loader';

// -----------
// Вывод альтернативного меню (level 2) каталога ( по хэштегам )
// И мобильный и десктоп
// -----------

export default function Get_Alt_DPRT(props){

const setup = useSelector(state => state.setup);
const [AllIs, setAllIs] = useState({loading:true, error:null});
const [mnulist,setMnulist] = useState([]);
const [notFound, setNotFound] = useState(false);

  let { tag, base, id } = useParams();
  let { path, url } = useRouteMatch();

useEffect(() => {
  window.scroll(0, 0);
  GetDATA(props.tag); 
}, [props.tag] );

var nURL = base ? url.slice(0, url.indexOf(base)-1) : url;

if(notFound){return <NotFound/>}
if(AllIs.error){ return <p>DPRT: {AllIs.error.message}</p> } 
if(AllIs.loading){ return <Loader/> } 

return(
  <Fragment>
      <ul className="tag">
        {
        mnulist.itm[0]?
          mnulist.itm.map((i, key) => <li key={key}><Link to={nURL+i.url }>{i.ttl}</Link></li>)
          :
          <li><Link to={nURL+mnulist.itm.url }>{mnulist.itm.ttl}</Link></li>
        }
      </ul>
</Fragment>
);

function GetDATA(cid){
  const form = new FormData()
        form.append('BaseNumber', 1);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-alt-dprt'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    if( data.dprt !== "" ){ 
      setMnulist(data.dprt);
      setAllIs({loading:false, error:false});
      } else 
       { setNotFound(true); }
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 
