import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import Base64 from "../Base64"

import {
  useRouteMatch
} from "react-router-dom";

//import * as  $ from 'jquery'
//import  'jquery.maskedinput'

import InputMask from 'react-input-mask'

export default function CallOrder(){

    const [state, setState] = useState({resp: false, loading:true, error:null});
    const setup = useSelector(state => state.setup);
    const [resp,setResp] = useState([]);

    let { url } = useRouteMatch();

    if(state.error){ return <p>ORDER: {state.error.message}</p> } 

    if(state.resp){ 
        //console.log('XML:', msg);
        return (
          <div className="pages">
                <h1>МЫ ВАМ ПЕРЕЗВОНИМ!</h1>
                <ShowContent content={resp}/>
          </div>
        )
    }



//$(() => { $("input#dfg").mask("+7 (999) 999-9999");})  
return(
    <form onSubmit={(ev)=>{SendCall(ev)}} name="sndcall" className='callOrder'>
        <InputMask required name="tel" id="dfg1" type="tel" mask="+7(999) 999-99-99" maskChar="-" placeholder="+7(___) ___ __ __" autoCorrect="off" autoComplete="off"/>
        <button type="submit" className="tel">ЗАКАЗАТЬ ЗВОНОК</button>

    </form>
)



function ShowContent(props){
    const dsc_txt = props.content;
    return( 
            <div dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(dsc_txt))}}/>
          ) 
    
    function sanitize(html) {
      var doc = document.createElement('div');
      doc.innerHTML = html;
      return doc.innerHTML;
    }
}


function SendCall(ev){
    ev.preventDefault()
    var CallForm = document.forms.sndcall;
    const form = new FormData()
          form.append('phone', CallForm.elements.tel.value );
          form.append('lang', setup.lang);
          form.append('url', url);


    const MyInit = { 
                method: 'POST',
                body: form
                };
  fetch(setup.domen+setup.folder+'orderCall', MyInit)
    .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })
    .then(data => {
        let msg = Base64.encode(data);
        //console.log('XML:', msg);
        setResp(msg);
        setState({loading:false, error:null, resp:true});  
        //dispatch({type:'CLEAR_BASKET', payload:null});
        //dispatch({type:'RESET_BQWN', payload:null});

    })
    .catch(error => {
        setState({...state, error:error});
    });
    
  }
}