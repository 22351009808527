import React, {Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import useMedia from "./js/useMedia"
import GetDPRT from "./DPRT/GetDPRT"
import GetDprtMobile from "./DPRT/GetDprtMobile"
import GetStepNavigation from "./StepNavigation"
import GetAltDprt_DT from "./DPRT/GetAltDprt_DT"
import { useParams } from "react-router-dom";
import NotFound from "./NotFound"

var upid = null;

// -----------
// Вывод альтернативного меню (level 3) каталога ( по хэштегам )
// И мобильный и десктоп
// -----------


export default function ShowAltDprt(props){

const tags = useSelector(state => state.tags.tags);
const bases = useSelector(state => state.navbases.nums);

    let { tag, base, id } = useParams();
  //let { path, url } = useRouteMatch();

//let small = useMedia("(max-width:420px)");
let large = useMedia("(min-width:650px)");


if(!tags[tag]){return <NotFound/>}

return(
<Fragment>
    {
      large ? 
      <div className="pages">
          <GetStepNavigation/>
              <table width="100%">
                <tbody>
                  <tr className="container dprts">
                    <td  className="menu">
                      <GetAltDprt_DT tag={tags[tag]}/>
                    </td>
                    <td className="dprts">
                      <GetDPRT/>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
      :
      <div className="item pages">
        <GetDprtMobile/>
      </div>
    }
      <hr />
  </Fragment>
  );

} 
