import React from 'react';
import {render} from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
//import { createStore} from 'redux'
import store from "./redux/store";

import './css/App.css';
import './css/Mob.css';
import './css/index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';

render(
		<Provider store={store}>
		  <Router>
			<App/>
		  </Router>
		</Provider>,
		  document.getElementById('root')
	  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();





