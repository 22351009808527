import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useRouteMatch } from "react-router-dom";

var msg = null;

export default function AddComment(){

    const [AllIs, setAllIs] = useState({loading:false, error:null, open:false});

    const setup = useSelector(state => state.setup);
    const bases = useSelector(state => state.navbases.nums);

    let { base, id } = useParams();



const open = () => setAllIs({loading:false, error:null, open:true}) 
const toggle = () => AllIs.open ? setAllIs({...AllIs, open:false}) : setAllIs({...AllIs, open:true})


if(AllIs.error){
    return (
        <Fragment>
            <button type="button" className="btn qst w" onClick={open}>ЗАДАТЬ ВОПРОС</button>
                {AllIs.open?
                <Fragment>
                <div className="dialog" onClick={toggle}>ASK QST: {AllIs.error.message}</div>
                </Fragment>:null}
        </Fragment>
            )
} 

if(AllIs.loading){
    return(  
    <Fragment>
        <button type="button" className="btn qst w" onClick={open}>ЗАДАТЬ ВОПРОС</button>
            {AllIs.open?
            <Fragment>
            <div className="dialog">
                <p className="finalmsg">{msg}</p>
                <button type="button" className="btn submit w" onClick={toggle}>ОК</button>
            </div>
            <div className="overlay" onClick={toggle}/>
            </Fragment>:null}
    </Fragment>
    )
} 


return(
<Fragment>
    <button type="button" className="btn qst w" onClick={open}>ЗАДАТЬ ВОПРОС</button>
     {AllIs.open?
        <Fragment>
                <form name="comment" onSubmit={(ev)=>{SaveDATA(ev)}} className="dialog">
                <div className="btn close" onClick={toggle}/>
                <h2>ЗАДАЙТЕ ВОПРОС СПЕЦИАЛИСТУ</h2>
                <input required type="name" name="nick" placeholder="Ваше имя"/>
                <input required type="email" name="email" placeholder="Email"/>
                <textarea required name="text" cols="30" rows="10" placeholder="Сообщение"></textarea>
                <button type="submit" className="btn submit w">ОТПРАВИТЬ</button>
                </form>
            <div className="overlay" onClick={toggle}/>
        </Fragment>
     :null
     }
</Fragment>
 )   

 function SaveDATA(ev){
    ev.preventDefault()
    var sndForm = document.forms.comment;

    const form = new FormData()
          form.append('unit', bases[base]);
          form.append('lang', setup.lang);
          form.append('id', id);
          form.append('usr', sndForm.elements.nick.value);
          form.append('ml', sndForm.elements.email.value);
          form.append('txt', sndForm.elements.text.value);
    const MyInit = { 
                method: 'POST',
                body: form
                };
  fetch(setup.domen+setup.folder+'add_comment', MyInit)
    .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })
    .then(data => {
        msg = data.resp;
        setAllIs({...AllIs, loading:true});
    })
    .catch(error => {
        setAllIs({...AllIs, error:error});
    });
  }


} //************ */