import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";

import "./addToBasket.css"
import number_format from "../js/number_format"


export default function AddToBasket(props){
    
  const dispatch = useDispatch()
    const basket = useSelector(state => state.basket);
    const btotal = useSelector(state => state.btotal);
    const bases = useSelector(state => state.navbases.nums);
    const i = props.itm;

const [state, setState] = useState({loading:false, error:null, open:false});


let { base, id } = useParams();


useEffect(() => { 
  //if(basket.has(i.id)){props.fr(basket.get(i.id).qwn);}
}, [btotal] );



const open = () => setState({loading:false, error:null, open:true}) 
const toggle = () => state.open ? setState({...state, open:false}) : setState({...state, open:true})

if(basket.has(i.id)){props.fr(basket.get(i.id).qwn);}
var total = basket.has(i.id) ? basket.get(i.id).total : 0;

    return (
        <Fragment>
            <button type="button" className="btn bskt w" onClick={open}>ЗАКАЗАТЬ</button>
            {state.open?
                <Fragment>
                        <div className="dialog basket">
                        <h2>ДОБВИТЬ В КОРЗИНУ</h2>
                            <div>{props.ttl.p} {props.ttl.v}<br/><span className="f12">{props.ttl.t}</span></div>
                            <br/>
                            <i>Модель: <span className="f12">{i.mdl}</span> ({i.id.slice(1)})</i>
                            {//<p>{itm.ttl}</p>
                            }
                            <p style={{textAlign:'center'}}>
                               <span className="f16">{number_format(i.prc, 2, '.', ' ')}</span> ₽. / <span>{number_format(total, 2, '.', ' ')}</span></p>
                            <div className="container bsk">
                                <button type="button" className="btn plusMinus f13" onClick={()=>sub()}>-</button>
                                <p><span className="f30 b">{
                                  basket.has(i.id)? basket.get(i.id).qwn : 0
                                  }</span> шт.</p>
                                <button type="button" className="btn plusMinus f13" onClick={()=>add()}>+</button>
                            </div>
                        <button type="button" className="btn submit w" onClick={()=>snd(i.id)}>OK</button>
                        </div>
                    <div className="overlay" onClick={()=>snd(i.id)}/>
                </Fragment>
            :null
            }
        </Fragment>
    )

function snd(id){
if( basket.has(id) && basket.get(id).qwn === 0 ){ 
  dispatch({type:'DEL_POSITION', payload:id});
}
dispatch({type:'SET_BASKET_SIZE', payload:null});
toggle();
}

function add(){
  let p =props.ttl.p;
  //console.log(p.replace(/"/g, '\\x22'));
 dispatch({type:'ADD_2_BASKET', payload:{id:i.id, ttl:{p:props.ttl.p, t:props.ttl.t, v:props.ttl.v}, mdl:i.mdl, prc: i.prc, qwn:1, base:bases[base]}});
}

function sub(){
 dispatch({type:'SUB_4M_BASKET', payload:i.id});
 }

}