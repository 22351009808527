import React, { useState, useEffect, Fragment } from 'react'
import Loader from "../Loader"
import SearchForm from "./SearchForm"
import useMedia from "../js/useMedia"
import { useTimeoutFn } from 'react-use';


export default function LoadSearchResult(){

    const [isReady, cancel, reset] = useTimeoutFn(ya_init, 1500);
    let large = useMedia("(min-width:650px)");

    useEffect(() => {
        ya(window,document,'yandex_site_callbacks');
        reset();
      }, [] );

return(<Fragment>
    {
        large? null: <SearchForm/>
    }
<div id="ya-site-results" data-bem="{&quot;tld&quot;: &quot;ru&quot;,&quot;language&quot;: &quot;ru&quot;,&quot;encoding&quot;: &quot;&quot;,&quot;htmlcss&quot;: &quot;1.x&quot;,&quot;updatehash&quot;: true}">
    {
        large? <Loader/> : null
    }
</div>
</Fragment>
)

function ya_init() {
    window.Ya.Site.Results.init();
    cancel();
}

function ya(w,d,c){
    var s=d.createElement('script'), h=d.getElementsByTagName('script')[0];
    s.type='text/javascript';
    s.async=true;
    s.charset='utf-8';
    s.src=(d.location.protocol==='https:'?'https:':'http:')+'//site.yandex.net/v2.0/js/all.js';
    h.parentNode.insertBefore(s,h);
    //(w[c]||(w[c]=[])).push(function(){Ya.Site.Results.init();})
}
    
} 


