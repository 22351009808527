import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux'

import Base64 from "../Base64"
import Loader from "../Loader"

import "./banner.css"
import { useCookies } from 'react-cookie';

export default function FloatBanner(props){
    const setup = useSelector(state => state.setup);
    
    const [banners, setBanners] = useState({qwn:0, itm:[]});
    const [state, setState] = useState({isLoading:true, error:null});
    const [cookies, setCookie, removeCookie] = useCookies(['hideBanner']);
    const [BanStyle, setBanStyle] = useState('banner tac')

    useEffect(() => {
        loadData('index-ban');
      }, [] );


    if(state.error){
        return <p>loadComments: {state.error.message}</p>
            } 
       
    if(state.isLoading){  return <Loader/> } 

return (
        <Fragment>
          {
           cookies.hideBanner!=='yes'? <Main/> : null
          }
        </Fragment>
)

function Main(){
  return(
    <Fragment>
      { banners.qwn>0 ?
        <div className={BanStyle}>
          <h2 className="red tac">ИНФОРМАЦИЯ</h2>
          <ul>
              {
                banners.qwn >1? banners.itm.map((itm, i) => 
                  <Fragment>
                      <Banner key={i} itm={itm}/>
                      <hr/>
                  </Fragment>)
                  : 
                  <Banner itm={banners.itm}/>
              }
          </ul>
          <div className="btn close" onClick={Close}/>
      </div> : null
      }
    </Fragment>         
  )
}


function Close(){
    setBanStyle('banner hidn');
    let date = new Date(Date.now() + 43200e3);
    setCookie('hideBanner', 'yes', {expires: date});
}

function Banner(props){
    let i= props.itm
    return(
    <li>
        <div dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(i.htm))}}/>
    </li>
    )
}

function sanitize(html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return doc.innerHTML;
  }
  


function loadData(cid){
   setState({ error: null, isLoading: true });
        const form = new FormData()
        form.append('BaseNumber', 0);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'banners-glavnaya'); //xsl - шаблон 
    
        const MyInit = {method: 'POST', body: form};
    
        fetch(setup.domen + setup.folder + 'floatban', MyInit)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(response.text() + 'Something went wrong ...');
            }
          })
          .then(data => {
            //dispatch({type:'LOAD_COMMENTS', payload: data});
            setBanners({qwn:data.qwn, itm:data.ban.itm}); 
            setState({ error: null, isLoading: false })
          })
          .catch(error => {
            setState({ error: error, isLoading: true })
          }
          );
      } 


}

