import React, { Fragment, useState, useEffect } from 'react';
import { render } from 'react-dom'
import '../css/lightBox.css'

var el = null;
var Counter = 0;
export default function LightBox(props){

	if(props !== null){ 
		el = document.querySelectorAll(props);
		for(let p of el)
			p.addEventListener('click', ShowPic);
	}
	return(<Fragment></Fragment>)

}
function ShowPic(ev){
	ev.preventDefault();
	var href = ev.target.parentNode.href;
	let activeImage = 0;
	Counter++;
	while(activeImage < el.length && href !== el[activeImage].href ) activeImage++;

return (
	render(<ShowBox activeImage={activeImage} counter={Counter}/>, document.getElementById('lbcont'))
)
}

function ShowBox(props){
	const [AllIs, setAllIs] = useState({loading:true, error:false, open:true});
	const [PicSrc, setPicSrc] = useState('../images/lightbox-ico-loading.gif');
	const [activeImage, setActiveImage] = useState(props.activeImage);
	
	const toggle = () => AllIs.open ? setAllIs({...AllIs, open:false}) : setAllIs({...AllIs, open:true})
	
	function nextPic(){
		if(activeImage >= el.length-1) setActiveImage(0) 
		 else
		  setActiveImage(activeImage+1)
	};

	function prevPic(){
		if(activeImage<1) setActiveImage(el.length-1) 
		 else
			setActiveImage(activeImage-1)
	};

	function ShowPic(){ setAllIs({...AllIs, loading:false}) }
	function HidePic(){ setAllIs({...AllIs, loading:true}) }

	useEffect(() => {
		setAllIs({...AllIs, open:true});
		setPicSrc(decodeURI(el[props.activeImage].href));
		setActiveImage(props.activeImage);
	}, [props.counter]);

	useEffect(() => {
		HidePic();
		setPicSrc(decodeURI(el[activeImage].href));
	  }, [activeImage] );

//console.log('PICSRC:', decodeURI(PicSrc).replace(/ /g, "_"));
return(
	<Fragment>
		{
		AllIs.open?
		<Fragment>
			<div className={AllIs.loading?'lbox loader':'lbox'}>
				<div className="btn close" onClick={toggle}/>
				{
					AllIs.loading ? null:
					<Fragment>
					{
						el.length > 1?
							<div>
								{
									activeImage>0?
										<div className="prev" onClick={prevPic}></div> : null
								}
								{
									activeImage < el.length-1?
										<div className="next" onClick={nextPic}></div> : null
								}
							</div> : null
					}
					</Fragment>
				}
				<img src={PicSrc} onClick={el.length<2?toggle:null} onLoad={ShowPic} className={AllIs.loading?'hide':'show'}/>
				{
				AllIs.loading ? null:
					<div className="b p5 wbg">
						{
							el.length > 1? 
							<Fragment>
								Фото {activeImage+1} из {el.length}
								{
									el[activeImage].lastChild.alt!==''?
									<span> | {el[activeImage].lastChild.alt}
									</span> : null
								}
							</Fragment>
							:
							<Fragment>					
								{
									el[activeImage].lastChild.alt!==''?
									<span>{el[activeImage].lastChild.alt}
									</span> : null
								}
							</Fragment>
						}
						</div>
				}				
			</div>
			<div className="lboverlay" onClick={toggle}/>
		</Fragment>
			: null
		}
	</Fragment>
)
}
//
