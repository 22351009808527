import React, { useState, useEffect, Fragment } from 'react';
import { useTimeoutFn } from 'react-use';
import Base64 from "../Base64"
import './search.css'



export default function SearchForm(){

const [isReady, cancel, reset] = useTimeoutFn(ya_init, 3000);

useEffect(() => {
    //cancel();
    ya(window,document,'yandex_site_callbacks');
    reset();
}, [] );
    
    return( 
        <Fragment>
            
<form action="https://indelta.ru/search" method="get" target="_self" acceptCharset="utf-8">
<div className="ya-site-form ya-site-form_inited_no" data-bem="{&quot;action&quot;:&quot;https://indelta.ru/search&quot;,&quot;language&quot;:&quot;ru&quot;,,&quot;publicname&quot;:&quot;Yandex Site Search #216388&quot;,,&quot;target&quot;:&quot;_self&quot;,&quot;tld&quot;:&quot;ru&quot;,&quot;type&quot;:3,&quot;searchid&quot;:216388}"
>

    <input name="text" id="search" type="search" placeholder="Поиск по сайту" autoComplete="off"/>

</div>
<input type="hidden" name="searchid" value="216388"/>
<input type="hidden" name="l10n" value="ru"/>
<input type="hidden" name="reqenc" value=""/>
<input type="hidden" name="web" value="0"/>
</form>
           
        </Fragment>
    )

    function ya_init() {
        window.Ya.Site.Form.init();
        cancel();
    }

    function ya(w,d,c){
        var s=d.createElement('script'),  h=d.getElementsByTagName('script')[0], e=d.documentElement;

         if((' '+e.className+' ').indexOf(' ya-page_js_yes ')===-1){e.className+=' ya-page_js_yes';}

         s.type='text/javascript';
         s.async=true;
         s.charset='utf-8';
         s.src=(d.location.protocol==='https:'?'https:':'http:')+'//site.yandex.net/v2.0/js/all.js';
         
         h.parentNode.insertBefore(s,h);
         //(w[c]||(w[c]=[])).push(function(){Ya.Site.Form.init()});
    }
         
    


}