import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "./Base64"

var upid = null;

export default function GetInfoBlock(props){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [data,setData] = useState({info:[]});

//console.log('COUNT:',count);

const id = props.blk;
const base = 'info';

useEffect(() => {
  GetDATA(id, bases[base]); 
}, [id] );



if(AllIs.error){
        return <p>INFO BLOCK: {AllIs.error.message}</p>
    } 

if(AllIs.loading){
        return( 
        <div style={{"fontSize":"7px"}} className="_3KHIQ _2h_fa">
          <div className="_1XybS"/><div className="_1XybS _3FRxt"/><div className="_1XybS _1o7ne"/>
        </div>)
} 


const menu = {width:"100%"}
const m = data.info;

return(
  <div style={{'padding':'10px 20px'}}>
   { 
     <div dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(m.data))}}/>
   }  
  </div>
);



function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}


function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
        form.append('data', 'get-infoBlock'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    
      setData({info:data.info});
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 


