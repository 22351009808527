import React, { Fragment } from 'react';
import { useSelector } from 'react-redux'
import {Helmet} from "react-helmet";
import GetNews from "./GetNews"
import GetNovinki from "./GetNovinki"

export default function News(props){
const setup = useSelector(state => state.setup);
var title = 'НОВОСТИ — Дельта Инжиниринг';
console.log('SMODE:', setup.smode);
console.log('WINDOW.LOCATION:', window.location.pathname + window.location.search);
    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="Новости — Дельта Инжиниринг. Свежая информация в области автоматизации производства"/>
          <meta name="keywords" content="Новости, новинки"/>
          <link rel="canonical" href={setup._domen+'/news'}/>
         </Helmet>    
          <div className="container pt50">
            <div>
              <GetNews/>
            </div>
            <div>
              <GetNovinki/>
            </div>
          </div>
      </Fragment>
    );
 


}