import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import useMedia from "../js/useMedia"
//import number_format from "../js/number_format"
import Base64 from "../Base64"
import Loader from "../Loader"

//import * as  $ from 'jquery'
//import  'jquery.maskedinput'
import InputMask from 'react-input-mask'
import './order.css'

export default function SendOrder(){
    
    const dispatch = useDispatch()
    const setup = useSelector(state => state.setup);
    const [state, setState] = useState({resp: false, loading:true, error:null});
    const basket = useSelector(state => state.basket);
    const btotal = useSelector(state => state.btotal);

    const [data,setData] = useState([]);
    const [resp,setResp] = useState([]);

    let small = useMedia("(max-width:420px)");
    
useEffect(() => {
        GetDATA('dostavka', 0); 
}, [] );


if(state.error){ return <p>ORDER: {state.error.message}</p> } 
if(state.loading){ return <Loader/> }
if(state.resp){ 
    //console.log('XML:', msg);
    return (
      <div className="pages">
            <h1>ВАША ЗАЯВКА ОТПРАВЛЕНА</h1>
            <ShowContent content={resp}/>
      </div>
    )
}

//$(() => { $("input#tel").mask("+7 (999) 999-9999");})  

return(
        <div className={small?"item pages":"pages"}>{
            basket.size>0?
            <Fragment>
                <h1 className={small?"itmttl":null}>ОФОРМЛЕНИЕ ЗАЯВКИ</h1>
                <br/><br/>
                    <ShowContent content={data.dsc}/>
                <form onSubmit={(ev)=>{SendOrder(ev)}} name="sndorder">
                    <input required placeholder="ФИО" type="text" name="name" className="orderform" autoCorrect="name"/>
                    <input required placeholder="name@domen.ru" type="email" name="email" className="orderform" autoCorrect="email"/>
                    <InputMask required mask="+7 (999) 999-9999" maskChar="-" placeholder="+7 (___) ___-____" type="tel" name="tel" className="orderform" autoCorrect="off"/>
                    <textarea name="comtext" placeholder="комментарии к оборудованию"  className="orderform"></textarea>
                    <button className="btn order w" type="submit">ОТПРВИТЬ ЗАЯВКУ</button>
                </form>
            </Fragment>
            :<span>В КОРЗИНЕ ПУСТО!</span>

        }
        </div>
)


function ShowContent(props){
    const dsc_txt = props.content;
    return( 
            <div dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(dsc_txt))}}/>
          ) 
    
    function sanitize(html) {
      var doc = document.createElement('div');
      doc.innerHTML = html;
      return doc.innerHTML;
    }
}

function SendOrder(ev){
    ev.preventDefault()
    var sndForm = document.forms.sndorder;
    const form = new FormData()
          form.append('nick', sndForm.elements.name.value);
          form.append('email', sndForm.elements.email.value);
          form.append('phone', sndForm.elements.tel.value );
          form.append('com_text', sndForm.elements.comtext.value );
          form.append('lang', setup.lang);
          form.append('btotal', btotal);
          form.append('basket', JSON.stringify([...basket]));

    const MyInit = { 
                method: 'POST',
                body: form
                };
  fetch(setup.domen+setup.folder+'order', MyInit)
    .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })
    .then(data => {
        let msg = Base64.encode(data);
        //console.log('XML:', msg);
        setResp(msg);
        setState({loading:false, error:null, resp:true});  
        //dispatch({type:'CLEAR_BASKET', payload:null});
        //dispatch({type:'RESET_BQWN', payload:null});

    })
    .catch(error => {
        setState({...state, error:error});
    });
    
  }

function GetDATA(cid, base){
        const form = new FormData()
              form.append('BaseNumber', base);
              form.append('lang', setup.lang);
              form.append('CurID', cid);
              form.append('data', 'get-dostavka-info'); //xsl - шаблон 
        const MyInit = { 
                    method: 'POST',
                    body: form
                    };
      fetch(setup.domen+setup.folder+'data', MyInit)
        .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(response.text()+'Something went wrong ...');
            }
        })
      
        .then(data => {
            setData(data.n);
            setState({...state, loading:false});
            })

        .catch(error => {
            setState({...state, loading:true, error:error});
         });
      }
}