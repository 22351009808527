import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import useMedia from "../js/useMedia"
import number_format from "../js/number_format"

export default function BasketItem(props){
    const itm = props.itm;

    const dispatch = useDispatch()
    const basket = useSelector(state => state.basket);
    
    const [qwn, setQwn] = useState(itm.qwn);

useEffect(()=>{

});

//let small = useMedia("(max-width:400px)");
let large = useMedia("(min-width:650px)");

const PlusMinusBlock = () =>{
return <div className="container bsk">
            <button type="button" className="btn plusMinus f13" onClick={()=>sub()}>-</button>
            <p><span className="f30 b">{itm.qwn}</span> шт.</p>
            <button type="button" className="btn plusMinus f13" onClick={()=>add()}>+</button>
        </div>
}

return (
    <tr>
    { large?
        <Fragment>
            <td>{itm.ttl.p}-{itm.ttl.v}-{itm.ttl.t}
            <p>Модель: {itm.mdl}</p>
            </td> 
            <td><span className="f14">{number_format(itm.prc, 2, '.', ' ')} ₽.</span></td>
            <td>
            <PlusMinusBlock/>
            </td>
            <td><span className="f14">{number_format(itm.total, 2, '.', ' ')} ₽.</span></td>
            <td>
            <div className="btn del" onClick={()=>del()}>X</div>
            </td>
        </Fragment>
        : 
        <td className="rel">
            <p className="btn del mob" onClick={()=>del()}>X</p>
            <br/>
            <p>{itm.ttl.p}<br/>{itm.ttl.v}-{itm.ttl.t}</p>
            <p>Модель: {itm.mdl}</p>
            <p className="f14"> <span className="f10">Цена: </span>{number_format(itm.prc, 2, '.', ' ')} ₽.</p>
           <PlusMinusBlock/>
           <p className="f16">{number_format(itm.total, 2, '.', ' ')} ₽.</p>
        </td>

    }
    </tr>
 )

function add(){
    dispatch({ type:'ADD_2_BASKET', payload:{id:itm.id} });
     setQwn(qwn+1);
}
   
function sub(){
    dispatch({type:'SUB_4M_BASKET', payload:itm.id});
    setQwn(qwn-1);
}

function del(){
    dispatch({type:'DEL_POSITION', payload:itm.id});
}

}