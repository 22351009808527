import React, { Fragment } from 'react';
import {Helmet} from "react-helmet";
import { useSelector, useDispatch } from 'react-redux'
//import Loader from "./Loader"
import BasketItem from "./BasketItem"
import useMedia from "../js/useMedia"
import number_format from "../js/number_format"
import {
  Link,
} from "react-router-dom";

//const basket1 = new Map();
//const basket = new Map();
var total = 0;
export default function ViewBasket(){

    //const dispatch = useDispatch()
    const setup = useSelector(state => state.setup);
    const basket = useSelector(state => state.basket);
    const bqwn = useSelector(state => state.bqwn);
    const btotal = useSelector(state => state.btotal);

    let small = useMedia("(max-width:400px)");
    let large = useMedia("(min-width:650px)");
    
    var title = 'КОРЗИНА — Дельта Инжиниринг';
    document.title = title;

return(
  <Fragment>
          <Helmet>
          <title>{title}</title>
          <meta name="description" content="Здесть вы можете оформить Ваш заказ"/>
          <meta name="keywords" content="Заказать, купить, список товара, оформить заказ"/>
         </Helmet>
  
<div className="item pages pt50">
            {
            basket.size > 0? 
            <Fragment>
              <h2 className="itmttl">ВАШ ЗАКАЗ</h2><br/><br/>
              <table className="price">
                <tbody>{
                large?
                    <tr>
                    <th>Название</th>
                    <th width="150">Цена</th>
                    <th width="200">Количество</th>
                    <th width="150">Сумма</th>
                    <th width="50"></th>
                  </tr>:null
                  }
                  {[...basket].map((itms)=>{
                      let key = itms[0];
                      let value = itms[1];
                      return <BasketItem key={key} itm = {value}/>
                  })}
                </tbody>
              </table>
              <p className="tae red">ИТОГО: <span className="f14 b">{number_format(btotal, 2, '.', ' ')} ₽.</span></p>
              <Link to="/order" className="btn order w">ОФОРМИТЬ ЗАКАЗ</Link>
            </Fragment>
            :
            <h2 className="itmttl">ВАША КОРЗИНА ПУСТА!</h2>
            }
</div>
</Fragment>
)
}