import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import Base64 from "../Base64"
import Loader from "../Loader"
//import Comment from "./Comment"
import "./comments.css"

export default function ShowComments(){

  const setup = useSelector(state => state.setup);
  const [comments, setComments] = useState({qwn:0, itm:[]});  
  const [state, setState] = useState({isLoading:true, error:null});
  const bases = useSelector(state => state.navbases.nums);

  let { base, id } = useParams();

useEffect(() => {
    loadComments(id);
}, [] );
  
const ListComments = useMemo(() => Main(comments), [comments.itm]);      
    
if(state.error){ return <p>loadComments: {state.error.message}/ {id}</p>} 
if(state.isLoading){  return <Loader/> } 

return (
        <Fragment>
          {
            ListComments
          }  
        </Fragment>
)

function Main(com){
  return com.qwn >0 ?
    <Fragment>
     <ul className="comments">
         {
          com.itm.map((itm, i) => <Comment key={i} itm={itm}/>)
         }
     </ul>
    </Fragment> 
    : 
    <noindex> Вопросов нет, комментарии еще не поспели, а отзывы приветствуются — будь первым! </noindex>

}

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerText;
}

function sanitize_html(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}


function ShowContent(props){
const dsc_txt = props.content;
return( 
        <p dangerouslySetInnerHTML={{ __html: sanitize_html(Base64.decode(dsc_txt))}}/>
      ) 
}

function Comment(p){
  const itm = p.itm;
  const time = new Date(itm.date*1000)
  return itm !==''?
    itm.otvet !==''?
                    <li>
                      <p>
                        <span>{itm.name}: </span>
                        {sanitize( Base64.decode(itm.vopros) )}
                      </p>
                      <ShowContent content={itm.otvet}/>
                      {
                      //<p className="tae">{time.toLocaleDateString("ru-RU")}</p>
                      }
                    </li>
                  :null
                  :null
}


function loadComments(cid){
   //setState({ error: null, isLoading: true });
        const form = new FormData()
        form.append('BaseNumber', bases[base]);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
    
        const MyInit = {method: 'POST', body: form};
    
        fetch(setup.domen + setup.folder + 'comments', MyInit)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(response.text() + 'Something went wrong ...');
            }
          })
          .then(data => {
            //dispatch({type:'LOAD_COMMENTS', payload: data});
            setComments({qwn:data.qwn, itm:data.itm}); 
            setState({ error: null, isLoading: false })
          })
          .catch(error => {
            setState({ error: error, isLoading: true })
          }
          );
      }
}