import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {useCss, useTimeoutFn} from 'react-use';
import Base64 from "./Base64"
import Loader from "./Loader"

import "./css/YaOtziv.css"

export default function YaOtziv(props){
const setup = useSelector(state => state.setup);
const [AllIs, setAllIs] = useState({loading:true, error:null});

const [content, setContent] = useState('ЗАГРУЖАЕТСЯ');


useEffect(() => { 
    GetOtziv();
}, [] ); 

if(AllIs.error){
    return <p>YaOtziv: {AllIs.error.message}</p>
} 
if(AllIs.loading){ return <Loader/> } 

return(
    <div className='yaotziv' dangerouslySetInnerHTML={{ __html: sanitize(content)}}/>
);

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}



function GetOtziv(){
    const form = new FormData()
          form.append('lang', 'ru');
          form.append('filtr', 'no');
        
    const MyInit = { 
                method: 'POST',
                body: form
                };
  fetch(setup.domen+setup.folder+'ya_otziv.php', MyInit)
    .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })
  
    .then(data => {
      //console.log('Otziv:', data);
     setContent(data);
     setAllIs({loading:false, error:false});
    })
    .catch(error => {
        setAllIs({loading:true, error:error});
     });
  }


} 


