import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "./Base64"

import './css/StepNav.css'

import {
  BrowserRouter as Router,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import Loader from './Loader';

export default function GetStepNavigation(){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);
const navbase = useSelector(state => state.navbases.itms);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [nav,setNav] = useState({lst:[]});

  let { base, pid, id } = useParams();
  let { path, url } = useRouteMatch();

//console.log('COUNT:',count);

var npID = pid != null ? pid : base;
var StepCount = 1;

useEffect(() => {
  if (id == null){id = base}
  GetStepNavi(id, bases[base]); 
}, [npID] );


if(AllIs.error){
        return <p>STEP NAV: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return (
  <ul className="stepnav" style={{'width':'30%'}}>
      <li>
        <Link to="/" title="На главную страницу. Каталог.">КАТАЛОГ</Link>
      </li>
  </ul>
) } 

const menu = {width:"100%"}
const m = nav.lst;
//console.log('NAAAV:', navbase[base].t.toLowerCase());
return(
  <ul className="stepnav" itemscope="" itemtype="https://schema.org/BreadcrumbList">
    <li itemprop="itemListElement" itemscope="" itemtype="https://schema.org/ListItem">
      <Link itemprop="item" to="/" title="На главную страницу. Каталог.">
        <span itemprop="name">КАТАЛОГ</span>
      </Link>
      <meta itemprop="position" content={StepCount}/>
    </li>
        {
        m[0].ttl !== '' ? 
        <Fragment>
            {
              navbase[base].t.toLowerCase() != m[0].ttl.toLowerCase()?
              <li itemprop="itemListElement" itemscope="" itemtype="https://schema.org/ListItem">
                <Link itemprop="item" to={`${m[0].url}`} title={m[0].ttl}>
                  <span itemprop="name">{m[0].ttl}</span>
                </Link>
                <meta itemprop="position" content={++StepCount} />

              </li>
              : null
            }
            <li itemprop="itemListElement" itemscope="" itemtype="https://schema.org/ListItem">
              <Link itemprop="item" to={`${m[1].url}`} title={m[1].ttl} classname="current">
                <span itemprop="name">{m[1].ttl}</span>
              </Link>
              <meta itemprop="position" content={++StepCount}/>
            </li>
        </Fragment>
        : null
      }
  </ul>
);


function GetStepNavi(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang);
        form.append('CurID', cid);
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'bcrumb', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    for (var i = 0; i < data.m.length; i++) {
          data.m[i].ttl = Base64.decode(data.m[i].ttl);
      }
    
      setNav({lst:data.m});
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} 
